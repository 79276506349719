import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    justify-content: center;
    align-items: center;
    padding: ${({ padding }) => padding || "50px 60px"};
    border-radius: 15px;
    gap: 20px;
    min-width: 320px;
    max-width: 450px;
  }

  .MuiDialogActions-root {
    width: 80%;

    > button {
      width: 50%;
      white-space: nowrap;
      padding: 10px 30px;
    }
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: center !important;
  padding: 0 !important;
  gap: 20px;
  margin-top: 10px;
`;

export const Title = styled.span`
  text-align: center;
  color: ${({ theme }) => theme.text};
  font-size: 26px;
  font-weight: 600;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.text};
  white-space: pre-wrap;
  line-height: 1.5;
  font-size: 16px;
  text-align: center;
`;

export const Icon = styled.img`
  width: auto;
  height: 120px;
`;

export const ErrorMessageRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-left: ${({ theme }) => `3px solid ${theme.danger}`};
  padding: 5px 10px;
  background-color: #f0f0f0;
  width: 100%;
  box-sizing: border-box;
`;
