export default {
  app: {
    name: "pre-book-platform cms",
  },
  login: {
    username: "用戶名",
    email: "電郵",
    password: "密碼",
    login: "登入",
    logout: "登出",
  },
  lang: {
    en: "英文",
    zh: "中文",
  },
  VIPBlackList: {
    name: "VIP/黑名單",
    action: "行動",
    csvUpload: "上載CSV",
    customerType: "客人種類",
    type: "種類",
    carNumber: "車牌號碼",
    remark: "備註",
    addNew: "新增",
    edit: "更改",
    filterTitle: "搜尋",
    sure: "確定刪除記錄？",
    description: "",
    VIP: "貴賓",
    blacklist: "黑名單",
    LPN: "車牌",
    save: "確定",
    creditCard: "信用卡",
    octopusCard: "八達通卡",
    whitelist: "貴賓",
    LicensePlateAccessControl: "車牌",
    CardAccessControl: "卡",
    cardType: "卡類型",
  },
  Booking: {
    savePage: "儲存頁面",
    bookingSummary: "訂單",
    bookingId: "訂單編號",
    leaseId: "Lease ID",
    evBookingId: "Ev訂單編號",
    evBookingRef: "Ev訂單編號",
    email: "電郵地址",
    registrationMark: "車牌號碼",
    secondaryLPN: "其它車牌",
    carpark: "停車場",
    bookingType: "種類",
    vehicleType: "車輛類別",
    serviceType: "服務類別",
    service: "服務",
    parkingStatus: "泊車狀態",
    bookingSetting: "設置",
    status: "狀態",
    remark: "備註",
    parkingBay: "停車位",
    group: "區",
    floor: "樓層",
    code: "號碼",
    reassign: "重新分配",
    manageParkingBay: "管理停車位",
    invoiceSummary: "發票",
    prebook: "預訂",
    casual: "非預訂",
    permanent_change: "Permanent Change",
    temporary_change: "Temporary Change",
    service_change: "Service Change",
    temporary: "Temporary",
    confirmedByAdmin: "已確認",
    confirmParkingSlot: "確認停車位",
    transmissionSummary: "Transmission Summary",
  },
  Button: {
    save: "儲存",
    update: "更新",
    close: "關閉",
    cancel: "取消",
    reset: "重置",
    search: "搜尋",
    delete: "刪除",
  },
  Status: {
    pending: "待定",
    confirmed: "確認",
    cancelled: "取消",
    completed: "完成",
    error: "錯誤",
    booked: "已預訂",
  },
  ServicePlan: {
    hourly: "時租",
    monthly: "月租",
    ev: "Ev",
    recurring: "Recurring",
    timeshare: "Timeshare",
    flexibleRenewal: "Flexible Renewal",
  },
  Invoice: {
    status: "狀態",
    type: "類別",
    issueDate: "開始時間",
    expireDate: "過期時間",
    amount: "金額",
    balance: "結餘",
  },
  Operational: {
    lowConfidence: "問題車牌",
    longStayCarpark: "長期停泊",
    longStayGate: "堵塞閘口",
    blockList: "黑名單",
    vipEntry: "貴賓",
    entry: "入場",
    exit: "離場",
    longStay: "長期停泊",
    pending: "未完成",
    monthly: "月租",
    hourly: "時租預訂",
    casual: "時租",
    bookingList: "預訂時租清單",
    inputCouponCode: "輸入優惠券",
    waiveParkingFee: "豁免",
    updatePaymentRecord: "更改付款記錄",
    manual: "Manual",
    online: "網上付款",
    fapd: "閘口付款",
    PrivateCar: "私家車",
    Lorry: "貨車",
    Motorcycle: "摩托車",
  },
  Form: {
    pleaseSelect: "請選擇",
    required: "必填項目",
  },
  EmailTemplate: {
    one_time_monthly_booking_confirmation:
      "One Time Monthly Booking Confirmation",
    booking_confirmation: "Monthly parking service confirmation notice",
    one_time_payment_notice: "One-time payment notice",
    expiry_notice: "Monthly parking service expiry notice",
    monthly_renewal_notice: "Monthly parking service renewal notice",
    termination_notice: "Monthly parking service termination notice",
    hourly_termination_notice: "Hourly Termination Notice",
    receipt_casual: "Receipt Casual",
    "3_times_ocr_failure_notice": "3 times OCR failure notice",
    confirmation_for_resident_identity: "Confirmation for resident identity",
    confirmation_for_resident_identity_without_booking:
      "Confirmation for resident identity without booking",
    rejection_of_resident_identity: "Rejection of resident identity",
    confirmation_of_registration: "Confirmation of registration",
    registration_complete: "Your registration is complete",
    reset_password: "Reset password",
    personal_info_update_notice: "Successful updated account details",
    password_updated: "Your password has been updated",
    vehicle_change_notice: "Vehicle Change Notice",
    offline_payment_link: "Offline Payment Link",
  },
  errorRecord: {
    status: "Status",
    dateTime: "Date & Time",
    merchant: "Merchant",
    errorModule: "Error Module",
    errorType: "Error Type",
    errorDescription: "Error Description",
    carNumber: "Registration Mark",
    transmissionId: "Transmission ID",
  },
};
