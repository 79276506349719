import styled from "styled-components";
import { TreeItem } from "@mui/x-tree-view";

export const Wrapper = styled.div`
  background-color: #fff;
  height: 100vh;
  position: fixed;
  box-shadow: 0px 0px 3px 0px #dedede;
  z-index: ${({ $showMenu }) => ($showMenu ? 999 : 0)};
  transition: opacity 300ms, visibility 300ms, width 300ms;
  visibility: ${({ $showMenu }) => ($showMenu ? "visible" : "hidden")};
  width: ${({ $showMenu, $slideMenuWidth }) =>
    $showMenu ? `${$slideMenuWidth}px` : "0px"};
  padding: ${({ $showMenu }) => ($showMenu ? "0px 10px" : "0px")};

  > div {
    > button {
      background-color: transparent;
      color: #258cf7;
      font-size: 13px;
    }
  }

  @media (max-width: 980px) {
    position: absolute;
    width: ${({ $showMenu }) => ($showMenu ? "300px" : "0px")};
    padding: ${({ $showMenu }) => ($showMenu ? "0px 10px" : "0px")};
    opacity: ${({ $showMenu }) => ($showMenu ? 1 : 0)};
    top: 0px;
  }
`;

export const HoverEffectTreeItem = styled(TreeItem)`
  &:before,
  &:after {
    display: inline-block;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
    -moz-transition: -moz-transform 0.3s, opacity 0.2s;
    transition: transform 0.3s, opacity 0.2s;
  }

  &:before {
    margin-right: 10px;
    content: "[";
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    transform: translateX(20px);
  }

  &:after {
    margin-left: 10px;
    content: "]";
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    transform: translateX(-20px);
  }
`;

export const OverLayer = styled.div`
  display: none;

  @media (max-width: 980px) {
    transition: opacity 300ms;
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    visibility: ${({ $showMenu }) => ($showMenu ? "visible" : "hidden")};
    z-index: ${({ $showMenu }) => ($showMenu ? 1 : 0)};
    top: 0px;
    opacity: ${({ $showMenu }) => ($showMenu ? 1 : 0)};
  }
`;

export const SlideMenuLogo = styled.div`
  width: 260px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms, visibility 300ms, width 300ms;
  margin: 0 !important;

  @media (max-width: 980px) {
    width: 300px;
  }
`;

export const LogoImg = styled.img`
  width: auto;
  height: 26px;
  margin: 0 10px;
`;

export const SlideMenuIcon = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 8px;
`;

export const MenuBody = styled.div`
  width: 100%;
  max-height: calc(100% - 100px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const MenuFooter = styled.div`
  height: 30px;
  position: absolute;
  bottom: 0;
  margin: 0;
  width: calc(100% - 20px);
  text-align: center;
  font-size: 12px;
  color: #9197a3;
`;
