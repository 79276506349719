import toastSuccessIcon from "assets/images/toastSuccess.svg";
import toastErrorIcon from "assets/images/toastError.svg";
import toastWarningIcon from "assets/images/toastWarning.svg";
import toastInfoIcon from "assets/images/toastInfo.svg";

function typeAttribute(type, theme) {
  switch (type) {
    case "error":
      return {
        icon: toastErrorIcon,
        color: theme.error,
      };

    case "info":
      return {
        icon: toastInfoIcon,
        color: theme.info,
      };

    case "warning":
      return {
        icon: toastWarningIcon,
        color: theme.warning,
      };

    case "success":
    default:
      return {
        icon: toastSuccessIcon,
        color: theme.success,
      };
  }
}

const TOAST_TIMEOUT = 3000;
const TRANSACTION_CONFIG = { tension: 125, friction: 20, precision: 0.1 };

export { typeAttribute, TOAST_TIMEOUT, TRANSACTION_CONFIG };
