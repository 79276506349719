import _, { sortBy } from "lodash";
import moment from "moment";
import React from "react";
import {
  EXCEPTION_ACTIONS,
  NECESSARY_PERMISSION,
} from "components/common/Layout/constants";
import lang from "../i18n/locale/zh";
import { OperationalStatusTag, StatusTag } from "./BaseStyle";

export const getMultiLangProperty = (item, lang) => {
  if (typeof item === "string") return item;
  if (!item) return "";
  if (typeof item !== "object") return "";
  if (item.hasOwnProperty(lang)) return item[lang];
  // if (item.hasOwnProperty("en")) return item.en;
  return "";
};

export const getWeekDay = (dayNumber = "") => {
  const dayString = dayNumber.toString();
  switch (dayString) {
    case "0":
      return "Sunday";
    case "1":
      return "Monday";
    case "2":
      return "Tuesday";
    case "3":
      return "Wednesday";
    case "4":
      return "Thursday";
    case "5":
      return "Friday";
    case "6":
      return "Saturday";

    case "7":
      return "PH.";

    default:
      return "";
  }
};

export function getSortedServicePlanTypeList(array = []) {
  const order = [
    "hourly",
    "ev",
    "monthly",
    "flexibleRenewal",
    "casual",
    "recurring",
    "timeshare",
  ];

  const sortedArray = array.sort((a, b) => order.indexOf(a) - order.indexOf(b));

  return sortedArray;
}

export const getWeekdayShortForm = (dayNumber) =>
  `${getWeekDay(dayNumber).toLowerCase().substring(0, 3)}`;

export function getSortedPeriod(array = []) {
  const weekdayOrder = ["1", "2", "3", "4", "5", "6", "0", "7"];

  const sortedArray = array.sort((a, b) => {
    const aFrom = moment(`${a.from}`, "HH:mm").valueOf();
    const bFrom = moment(`${b.from}`, "HH:mm").valueOf();

    if (weekdayOrder.indexOf(a.weekday) > weekdayOrder.indexOf(b.weekday)) {
      return 1;
    }

    if (weekdayOrder.indexOf(a.weekday) < weekdayOrder.indexOf(b.weekday)) {
      return -1;
    }

    if (aFrom < bFrom) {
      return -1;
    }
    if (aFrom > bFrom) {
      return 1;
    }

    return 0;
  });

  return sortedArray;
}

export function calcDiffTime(time) {
  const diffDays = Math.floor(moment(moment()).diff(time, "days"));
  const diffHours = Math.floor(moment(moment()).diff(time, "hours"));
  const diffMins = Math.floor(moment(moment()).diff(time, "minutes"));
  const diffSeconds = moment(moment()).diff(time, "seconds");

  if (diffDays < 0 || diffHours < 0 || diffMins < 0 || diffSeconds < 0) {
    return "";
  }

  if (diffSeconds < 60) {
    return `${diffSeconds} seconds ago`;
  }

  if (diffMins < 60) {
    return `${diffMins} minutes ago`;
  }

  if (diffHours < 24) {
    return `${diffHours} hours ago`;
  }

  return `${diffDays} days ago`;
}

export function getStatusColorTag(data = "", theme, showTc) {
  const UpperCase = showTc ? lang.Status[data] : data.toUpperCase();

  switch (data) {
    case "success":
    case "active":
    case "scheduled":
    case "paid":
    case "completed":
    case "confirmed":
    case "publish":
    case "available":
    case "booked":
    case "approved":
    case "solved":
    case "entered":
    case "parked":
      return (
        <StatusTag $bgColor={theme.primary} color="#fff">
          {UpperCase}
        </StatusTag>
      );

    case "error":
    case "inactive":
    case "failed":
    case "unavailable":
    case "rejected":
    case "fail":
    case "wrongBay":
    case "blacklisted":
      return (
        <StatusTag $bgColor="#FFCECE" color={theme.danger}>
          {UpperCase}
        </StatusTag>
      );

    case "pending":
    case "pendingToRenew":
    case "processing":
    case "pendingToProcess":
    case "leaving":
    case "charging":
    case "charged":
      return (
        <StatusTag
          $bgColor={theme.primary}
          color="#fff"
          style={{ opacity: 0.6 }}
        >
          {UpperCase}
        </StatusTag>
      );

    case "expired":
      return (
        <StatusTag
          $bgColor={theme.secondary}
          color={theme.primary}
          style={{ opacity: 0.6 }}
        >
          {UpperCase}
        </StatusTag>
      );

    case "cancel":
    case "cancelled":
    case "terminated":
    case "hide":
      return (
        <StatusTag $bgColor="#dedede" color="#fff">
          {UpperCase}
        </StatusTag>
      );

    case "changed":
    case "overpaid":
    case "sent":
    case "longStay":
      return (
        <StatusTag $bgColor={theme.primary} color="#fff">
          {UpperCase}
        </StatusTag>
      );

    case "voided":
      return (
        <StatusTag $bgColor="#888" color="#fff">
          {UpperCase}
        </StatusTag>
      );

    case "underpaid":
      return (
        <StatusTag
          $bgColor={theme.primary}
          color="#fff"
          style={{ opacity: 0.6 }}
        >
          {UpperCase}
        </StatusTag>
      );
    default:
      return <span>{UpperCase}</span>;
  }
}

export function getOperationalStatusTag(data = "", theme) {
  const value = lang.Operational[data];
  switch (data) {
    case "pending":
    case "processing":
    case "pendingToProcess":
      return (
        <OperationalStatusTag $bgColor={theme.blue} color={theme.darkBlue}>
          {value}
        </OperationalStatusTag>
      );
    case "cancel":
    case "cancelled": {
      return (
        <OperationalStatusTag $bgColor={theme.danger} color={theme.textDanger}>
          {value}
        </OperationalStatusTag>
      );
    }
    case "entry":
    case "extended": {
      return (
        <OperationalStatusTag $bgColor={theme.confirm}>
          {value}
        </OperationalStatusTag>
      );
    }
    case "exit":
    case "exited": {
      return (
        <OperationalStatusTag $bgColor={theme.success}>
          {value}
        </OperationalStatusTag>
      );
    }
    case "black": {
      return (
        <OperationalStatusTag $bgColor={theme.gray} color="#000">
          {value}
        </OperationalStatusTag>
      );
    }
    case "block":
    case "blockList":
    case "longStayGate":
    case "longStayCarpark":
    case "lowConfidence":
    case "vipEntry": {
      return (
        <OperationalStatusTag $bgColor={theme.bgDanger} color={theme.danger}>
          {value}
        </OperationalStatusTag>
      );
    }
    default:
      return <span>{value}</span>;
  }
}

export function getAdjustment(adjustment) {
  if (adjustment === 0) {
    return "$0";
  }

  return `${adjustment > 0 ? "+" : "-"} $${Math.abs(adjustment)}`;
}

export function getEvPrice(id, price, HourlyPolicies = [], selectedSpaceId) {
  function getSameWeekDayArrayIndex(e) {
    const { weekday, _id } =
      HourlyPolicies.find((v) => v._id === e.HourlyPolicy) || {};

    const sameWeekDayArrayIndex = HourlyPolicies.filter(
      (v) => v.weekday === weekday
    ).findIndex((t) => t._id === _id);

    return sameWeekDayArrayIndex;
  }

  if (Array.isArray(price)) {
    if (id) {
      const priceArray =
        price.find(({ ServicePlan }) => ServicePlan === selectedSpaceId)
          ?.HourlyPolicies || [];

      return priceArray.map((e) => {
        const servicePlanIndex = price.findIndex(
          ({ ServicePlan }) => ServicePlan === selectedSpaceId
        );

        return {
          ...e,
          ...HourlyPolicies.find((v) => v._id === e.HourlyPolicy),
          sameWeekDayArrayIndex: getSameWeekDayArrayIndex(e),
          servicePlanIndex,
        };
      });
    }

    return price.map((e) => ({
      ...e,
      ...HourlyPolicies.find((v) => v._id === e.HourlyPolicy),
      sameWeekDayArrayIndex: getSameWeekDayArrayIndex(e),
    }));
  }
  return price;
}

export function InsertSpaceBeforeCapitalLetters(string = "") {
  if (!string) {
    return "";
  }

  return string.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function getTypeOfPolicyPlan(servicePlanType) {
  if (servicePlanType === "hourly") {
    return "HourlyPolicyPlan";
  }

  if (servicePlanType === "ev") {
    return "EVPolicyPlan";
  }

  if (servicePlanType === "monthly") {
    return "MonthlyPolicyPlan";
  }

  if (servicePlanType === "flexibleRenewal") {
    return "FlexibleRenewalPolicyPlan";
  }

  return " ";
}

function setTime(time, minute) {
  return time.set({ minute, second: 0, millisecond: 0 });
}

export const Get15MinutesBufferTime = (time) => {
  const bufferTime = 15;

  const output = time.clone().add(bufferTime, "minutes");

  const totalSeconds = output.minutes() * 60 + output.second();

  if (totalSeconds > 0 && totalSeconds < 900) {
    return setTime(output, 15);
  }
  if (totalSeconds > 900 && totalSeconds < 1800) {
    return setTime(output, 30);
  }

  if (totalSeconds > 1800 && totalSeconds < 2700) {
    return setTime(output, 45);
  }

  if (totalSeconds > 2700) {
    const clonedTime = output.clone();

    return clonedTime
      .add("1", "hour")
      .set({ minute: 0, second: 0, millisecond: 0 });
  }

  return setTime(output, output.minutes());
};

export const GetInstantBookingTime = (time, type = "hourly") => {
  const output = time.clone(); // .set({ second: 0, millisecond: 0 });

  const totalSeconds = output.minutes() * 60 + output.second();

  if (totalSeconds > 0 && totalSeconds < 900) {
    return setTime(output, type === "ev" ? 0 : 15);
  }

  if (totalSeconds > 900 && totalSeconds < 1800) {
    return setTime(output, type === "ev" ? 15 : 30);
  }

  if (totalSeconds > 1800 && totalSeconds < 2700) {
    return setTime(output, type === "ev" ? 30 : 45);
  }

  if (totalSeconds > 2700 && totalSeconds <= 3599) {
    return type === "ev"
      ? setTime(output, 45)
      : setTime(output, 0).clone().add(1, "hour");
  }

  return setTime(output, output.minutes());
};

export function checkArrayEqual(array1 = [], array2 = []) {
  const objectsEqual = (o1, o2) =>
    typeof o1 === "object" && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
      : o1 === o2;

  const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

  return arraysEqual(array1, array2);
}

export function isEmpty(val) {
  if (typeof val === "boolean") {
    return !val;
  }
  return _.isEmpty(val);
}

export function copyToClipboard(text, toastHandler) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
    return toastHandler("Copied", "success");
  }

  const element = document.createElement("span");
  element.textContent = text;
  element.style.whiteSpace = "pre";
  document.body.appendChild(element);
  const selection = window.getSelection();
  const range = document.createRange();
  selection.removeAllRanges();
  range.selectNode(element);
  selection.addRange(range);
  document.execCommand("copy");
  selection.removeAllRanges();
  document.body.removeChild(element);
  toastHandler("Copied", "success");

  return null;
}

export function convertThousandsWithComma(total = "") {
  if (total === "-") {
    return total;
  }

  if (typeof total === "number") {
    return total.toLocaleString("en");
  }

  return total ? parseFloat(total).toLocaleString("en") : "-";
}

export function getSubTitle(subTotal, model) {
  if (model === "PriceSetting") {
    return `$${convertThousandsWithComma(subTotal).replace("-", "-$")}`;
  }

  return `$${convertThousandsWithComma(subTotal)}`;
}

export function getDescription(description, i18n) {
  return `\n${getMultiLangProperty(description, i18n.language)
    .replace("From", "")
    .replace("to", "-")
    .trim()}`;
}

export function isNecessaryPermission(resource, action) {
  return (
    NECESSARY_PERMISSION.findIndex(
      (necessaryPermission) =>
        necessaryPermission.resource === resource &&
        necessaryPermission.actions.includes(action)
    ) !== -1
  );
}

export function isExceptionPermission(resource, action) {
  return (
    EXCEPTION_ACTIONS.findIndex(
      (exceptionAction) =>
        exceptionAction.module === resource && exceptionAction.action === action
    ) !== -1
  );
}

export function getWeekDayString(day, holiday = []) {
  if (!day || !holiday) {
    return "";
  }

  const isHoliday = !isEmpty(
    holiday.find((v) => moment(v.date, "YYYY-MM-DD").isSame(day, "date"))
  );

  const weekdayOfDay = isHoliday ? "7" : day.format("e");

  return weekdayOfDay;
}

export function getBookingTimeDiff(
  from,
  to,
  servicePlan = {},
  datePrices = []
) {
  const { period, type } = servicePlan;

  if (
    !from ||
    !to ||
    !type ||
    isEmpty(servicePlan) ||
    !datePrices ||
    datePrices.length === 0
  ) {
    return 0;
  }

  if (type === "flexibleRenewal") {
    const FlexibleRenewalDatePrice =
      datePrices.find((e) => e.type === "FlexibleRenewalDatePrice") || {};

    const startDate = from.clone();

    const day = from.clone().date();

    const { renewalPeriod = {} } = FlexibleRenewalDatePrice || {};
    const { start: renewalDay } = renewalPeriod || {};

    const dayOfHalfMonth = 15;

    if (day <= dayOfHalfMonth) {
      return Math.round(to.diff(startDate.startOf("month"), "months", true));
    }

    if (day > dayOfHalfMonth && day < renewalDay) {
      return period - 1 + 0.5;
    }

    if (day >= renewalDay) {
      return period + 0.5;
    }
  }

  // if(type === 'recurring'){

  // }

  if (type === "hourly" || type === "ev") {
    return to.diff(from, "hours", true);
  }

  return 0;
}

export function capitalize(string) {
  if (string !== null && string !== undefined) {
    const capitalized = string.charAt(0).toUpperCase() + string.slice(1);

    return capitalized.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  return "";
}

export function getUserFullName(object = {}, lang = "en") {
  if (isEmpty(object)) {
    return "";
  }
  const { firstName = "", lastName = "" } = object || {};

  return lang === "en" ? `${firstName} ${lastName}` : `${lastName}${firstName}`;
}

export function getDateFormat(inputDate, format = "") {
  if (!inputDate || typeof inputDate !== "object") {
    return "";
  }

  if (!moment(inputDate).isValid()) {
    return "-";
  }

  const date = moment(inputDate.toISOString());

  if (format) {
    return date.format(format);
  }

  return "-";
}
