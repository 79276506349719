import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  z-index: 9;
  height: 30px;

  svg {
    fill: #fff;
  }
`;

export const LeftRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  > div {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 980px) {
    width: 280px;
  }
`;

export const RightRow = styled.div`
  display: flex;
  padding-right: 20px;

  div {
    margin: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MyAccount = styled.a`
  color: #fff;
  font-size: 10px;
  cursor: pointer;
`;

export const LogOut = styled.button`
  color: #fff;
  font-size: 10px;

  text-decoration: underline;
`;
export const MenuLogo = styled.div`
  @media (max-width: 980px) {
    display: none;
  }
`;

export const LogoImg = styled.img`
  width: auto;
  height: 26px;
  margin: 0 10px;
`;

export const LayoutContainer = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  transition: all 300ms;

  .copyRight {
    transition: all 300ms;
    font-size: 15px;
    color: #dbdbdb;
    text-align: center;
    margin-left: ${({ $slideMenuWidth, $showMenu }) =>
      $showMenu ? `${$slideMenuWidth}px` : 0};
    background-color: #fff;
  }

  .mainContainer {
    transition: all 300ms;
    padding: 20px;
    margin-top: ${({ $headerHeight }) => `${$headerHeight}px`};
    margin-left: ${({ $slideMenuWidth, $showMenu }) =>
      $showMenu ? `${$slideMenuWidth + 20}px` : 0};
    background-color: #fff;
    flex: 1;
    height: ${({ $headerHeight }) => `calc(100% - ${$headerHeight + 40}px)`};
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .title {
      font-size: 35px;
      color: ${({ theme }) => theme.text};
      font-weight: 500;
      margin: 0px;
    }

    > div {
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    @media (max-width: 980px) {
      padding: 20px;
    }
  }
`;

export const Name = styled.span`
  color: #fff;
  margin-left: 10px;
  font-size: 16px;

  @media (max-width: 980px) {
    font-size: 14px;
  }
`;

export const CustomerButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;

  > img {
    height: 16px;
    width: 16px;
  }
`;

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  width: 20px;
  position: absolute;
  left: ${({ $showMenu }) => ($showMenu ? "280px" : 0)};
  transition: all ease-in-out 3ms;

  @media (max-width: 980px) {
    left: ${({ $showMenu }) => ($showMenu ? "unset" : "10px")};
  }
`;

export const Divider = styled.div`
  font-size: 14px;
  color: #fff;
`;
