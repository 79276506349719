import styled from "styled-components";
import { animated } from "@react-spring/web";

export const StyledToast = styled(animated.div)`
  box-sizing: border-box;
  position: relative;
  min-width: 300px;
  max-width: 500px;
  border-radius: 5px;
`;

export const BasicContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 20px;
`;

export const Icon = styled.img`
  width: 25px;
  height: 25px;
`;

export const Content = styled.div`
  padding: 20px 30px 20px 20px;
  background-color: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  border-left: ${({ color }) => `5px solid ${color}`};
  align-items: center;
  position: relative;
  display: flex;
  gap: 10px;
`;
