import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "evConnector";

const useEvConnectorModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);
  const { getList, getOne, createOne, updateOne, deleteOne } = BaseActions(
    module,
    {
      Merchant,
      isSystem,
      token,
    }
  );

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    deleteOne,
  };
};

const EvConnectorModel = BaseModel(useEvConnectorModel, "EvConnectorModel");

export { useEvConnectorModel };
export default EvConnectorModel;
