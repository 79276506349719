import styled from "styled-components";

export const StyledP = styled.p`
  color: ${(props) =>
    props.theme[props.color]
      ? `${props.theme[props.color]}`
      : props.theme.black};
  font-size: ${({ fontSize }) => fontSize && ` ${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ $noMargin, margin }) => ($noMargin ? 0 : margin)};
  text-align: ${({ textAlign }) => textAlign};
  white-space: ${({ $whiteSpace }) => $whiteSpace};

  @media (max-width: 980px) {
    font-size: ${({ sx }) => sx?.fontSize && `${sx?.fontSize}px`};
    margin: ${({ sx }) => (sx?.noMargin ? 0 : sx?.margin)};
    text-align: ${({ sx }) => sx?.textAlign};
  }
`;

export const StyledSpan = styled.span`
  color: ${(props) =>
    props.theme[props.color]
      ? `${props.theme[props.color]}`
      : props.theme.black};
  font-size: ${({ fontSize }) => fontSize && `${fontSize}px`};
  font-weight: ${({ fontWeight }) => fontWeight};
  margin: ${({ margin }) => margin};

  @media (max-width: 980px) {
    font-size: ${({ sx }) => `${sx?.fontSize}px`};
    margin: ${({ sx }) => sx?.margin};
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  align-items: ${({ $alignItems }) => $alignItems || "center"};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  gap: ${({ gap }) => gap && `${gap}px`};

  @media (max-width: 980px) {
    font-size: ${({ sx }) => sx?.fontSize};
    margin: ${({ sx }) => sx?.margin};
    padding: ${({ sx }) => sx?.padding};
    justify-content: ${({ sx }) => sx?.justifyContent};
    align-items: ${({ sx }) => sx?.alignItems};
    flex-direction: ${({ sx }) => sx?.flexDirection};
  }
`;

export const StatusTag = styled.div`
  background-color: ${({ $bgColor }) => $bgColor};
  padding: 5px 30px;
  color: ${({ color }) => color};
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 20px;
  font-weight: bold;
  width: auto;
  margin: 0 auto;
  font-size: 14px;
`;

export const OperationalStatusTag = styled.div`
  background-color: ${({ $bgColor }) => $bgColor};
  padding: 3px 15px;
  color: ${({ color }) => color};
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 4px;
  font-weight: bold;
  min-width: 130px;
  margin: 0 auto;
  font-size: 18px;
`;
