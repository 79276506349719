import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import BaseModel from "base/BaseModel";
import APIHelper from "base/APIHelper";
import UserModel from "./userModel";

const module = "cameraConnectionLog";

const useCameraConnectionModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);
  const { getList, getOne, createOne, updateOne } = BaseActions(module, {
    Merchant,
    isSystem,
    token,
  });

  async function getLogByRef(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/ref/${id}`,
      isSystem ? params : { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getLogByRef,
  };
};

const CameraConnectionModel = BaseModel(
  useCameraConnectionModel,
  "CameraConnectionModel"
);

export { useCameraConnectionModel };

export default CameraConnectionModel;
