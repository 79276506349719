import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

export const app = initializeApp({
  apiKey: "AIzaSyCtRFxlXxs5GTreSyYn7LKy15IJupGRFc0",
  authDomain: "total-parking-management.firebaseapp.com",
  projectId: "total-parking-management",
  storageBucket: "total-parking-management.appspot.com",
  messagingSenderId: "833972831777",
  appId: "1:833972831777:web:621e9862865536312a0560",
  measurementId: "G-53WNP34BY7",
});

export const messaging = async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const requestFcmToken = async (setToken) => {
  try {
    await navigator.serviceWorker.register("/firebase-messaging-sw.js", {
      scope: "/",
    });
    const messagingResolve = await messaging();
    const currentToken = await getToken(messagingResolve, {
      vapidKey:
        "BPOeGF4oVgnGAQ_1YjvqWVLCFZ2ySW0M36FXBbv8MWU-6ctEcefHaSgVNWWSGLnbPLGe9oycwhA2iVOuK7GxgOY",
    });
    if (currentToken) setToken(currentToken);
  } catch (err) {
    console.log(err);
  }
};
