import React, { useCallback, useContext, useMemo, useState } from "react";
import Slide from "@mui/material/Slide";
import PopUpModel from "models/popUpModel";
import { StyledP } from "base/BaseStyle";
import LoadingSpinner from "../../../LoadingSpinner";
import Button from "../../../CustomButton";
import {
  StyledDialog,
  Description,
  Title,
  Icon,
  StyledDialogActions,
} from "./styles";
import { typeAttribute } from "../constants";
import Inputs from "../components/Inputs";
import MessageModal from "../../Modal/MessageModal";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function BaseAlert() {
  const {
    toggleAlert,
    open,
    props,
    theme,
    changeInputValue,
    inputValue,
    validationMessage,
    changeValidationMessage,
    isLoading,
    changeIsLoading,
  } = useContext(PopUpModel.Context);

  const {
    type,
    onClose = () => {},
    title = "",
    closeText = "",
    confirmText = "",
    onConfirm = () => {},
    text = "",
    icon,
    footer = null,
    descriptionstyle = {},
    onClickLeaveWithoutSave = () => {},
    render,
    showConfirmButton = true,
    showCancelButton = true,
    allowOutsideClick = true,
    inputType = "",
    closeAfterConfirm = true,
    remarks = "",
    confirmButtonStyle,
    onError = () => {},
  } = props || {};

  const [prevProps, setPrevProps] = useState({});
  const [alertState, setAlertState] = useState();

  const changeAlertState = useCallback((value) => setAlertState(value), []);

  async function asyncConfirm() {
    const functionName = onConfirm?.constructor?.name;
    const outputValue = inputType ? inputValue : alertState;

    if (functionName === "AsyncFunction") {
      try {
        changeIsLoading(true);

        const response = await onConfirm(outputValue);

        if (typeof response === "boolean" && !response) {
          throw response;
        }

        changeIsLoading(false);

        if (!closeAfterConfirm) {
          return;
        }

        toggleAlert(null);
        changeAlertState();
      } catch (error) {
        changeIsLoading(false);
        if (error) {
          onError(error);
        }
      }

      return;
    }

    onConfirm(outputValue);
    toggleAlert(null);
    changeAlertState();
  }

  async function asyncCancel() {
    try {
      await onClose();
      toggleAlert(null);
    } catch (error) {
      //
    }
  }

  const defaultAttribute = useMemo(() => {
    if (type) setPrevProps(props);
    return typeAttribute(type, theme);
  }, [type]);

  if (type === "message" || prevProps?.type === "message") {
    const {
      closeText: messageCloseText,
      confirmText: messageConfirmText,
      showConfirmButton: messageConfirmButton,
      showCancelButton: messageCancelButton,
    } = prevProps || props || {};

    return (
      <MessageModal
        isOpen={open}
        title={title}
        icon={icon || defaultAttribute?.icon}
        description={text}
        closeText={messageCloseText}
        confirmText={messageConfirmText}
        showConfirmButton={messageConfirmButton}
        showCancelButton={messageCancelButton}
        onConfirm={asyncConfirm}
        handleClose={asyncCancel}
        confirmButtonStyle={confirmButtonStyle}
      >
        {render && React.cloneElement(render, { changeAlertState, alertState })}
      </MessageModal>
    );
  }

  return (
    <StyledDialog
      open={open}
      keepMounted={false}
      TransitionComponent={Transition}
      onClose={(e, reason) => {
        if ((reason === "backdropClick" && !allowOutsideClick) || isLoading) {
          return;
        }
        toggleAlert(null);
        onClose();
      }}
    >
      {(icon || defaultAttribute?.icon) && (
        <Icon src={icon || defaultAttribute?.icon} alt="alert" />
      )}
      {(title || defaultAttribute.title) && (
        <Title>{title || defaultAttribute.title}</Title>
      )}
      {(text || defaultAttribute.text) && (
        <Description style={descriptionstyle}>
          {text || defaultAttribute.text}
        </Description>
      )}

      {render && React.cloneElement(render, { changeAlertState, alertState })}

      {inputType && (
        <Inputs
          type={inputType}
          value={inputValue}
          changeInputValue={changeInputValue}
          validationMessage={validationMessage}
          changeValidationMessage={changeValidationMessage}
          asyncConfirm={asyncConfirm}
        />
      )}

      {remarks && (
        <StyledP color="gray" fontSize="14" style={{ whiteSpace: "pre" }}>
          {remarks}
        </StyledP>
      )}

      <StyledDialogActions>
        {showCancelButton && (
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={asyncCancel}
          >
            {closeText || defaultAttribute.closeText}
          </Button>
        )}

        {showConfirmButton && (
          <Button disabled={isLoading} onClick={asyncConfirm}>
            {isLoading ? "" : confirmText || defaultAttribute.confirmText}
            {isLoading && <LoadingSpinner width={30} height={30} />}
          </Button>
        )}
      </StyledDialogActions>

      {footer ||
        (type === "unsaved" && (
          <Button
            variant="link"
            $hovercolor={theme.primary}
            style={{ marginTop: "0.5rem" }}
            onClick={() => {
              onClickLeaveWithoutSave();
              toggleAlert(null);
            }}
          >
            Leave without saving
          </Button>
        ))}
    </StyledDialog>
  );
}

export default BaseAlert;
