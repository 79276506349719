import { useContext, useState } from "react";
import BaseActions, { APIRequestGenerator } from "../base/BaseActions";
import BaseModel from "../base/BaseModel";
import UserModel from "./userModel";
import APIHelper from "../base/APIHelper";

const module = "migrationConfig";

const useMigrationConfigModel = (savedModelInstance = {}) => {
  const { token, isSystem, Merchant } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, getSchema } = BaseActions(
    module,
    { isSystem, token, Merchant }
  );

  const [isMigrating, setIsMigrating] = useState(false);

  // /migrationConfig/migrationInfo/:system?code=get_merchants&module=Merchant

  async function getMigrationInfo(system, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/migrationInfo/${system}`,
      params,
      token
    );

    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      setIsMigrating(true);
      return Promise.resolve(response);
    } catch (error) {
      setIsMigrating(false);
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getSchema,
    getMigrationInfo,

    isMigrating,
  };
};

const MigrationConfigModel = BaseModel(
  useMigrationConfigModel,
  "MigrationConfigModel"
);

export { useMigrationConfigModel };
export default MigrationConfigModel;
