export default function CustomCaseTable(customCase, data = {}) {
  const {
    ref = "",
    minStartTime = "",
    minEndTime = "",
    fromTime = "",
    toTime = "",
    carparkId = "",
    hour = "",
  } = data || {};
  switch (customCase) {
    case "PBP001":
      return {
        en: `Failed to sync data to Carpark System (Ref: ${ref})`,
        zh: `Failed to sync data to Carpark System (Ref: ${ref})`,
      };

    case "PBP002":
      return {
        en: "Error! No record for this vehicle is found. Please make sure the vehicle registration mark provided is correct. Please try again or contact with carpark staffs.\nError Code: 301",
        zh: "錯誤！找不到車輛記錄，請確認所輸入車牌號碼是否正確，請稍後再試或聯繫停車場工作人員。\n錯誤碼： 301",
      };

    case "PBP003":
      return {
        en: "Error! No record for this vehicle is found. Please make sure the vehicle registration mark provided is correct. Please try again or contact with carpark staffs.\nError Code: 302",
        zh: "錯誤！找不到車輛記錄，請確認所輸入車牌號碼是否正確，請稍後再試或聯繫停車場工作人員。\n錯誤碼： 302",
      };

    case "PBP004":
      return {
        en: "Error! No record for this vehicle is found. Please make sure the vehicle registration mark provided is correct. Please try again or contact with carpark staffs.\nError Code: 303",
        zh: "錯誤！找不到車輛記錄，請確認所輸入車牌號碼是否正確，請稍後再試或聯繫停車場工作人員。\n錯誤碼： 303",
      };

    case "PBP005":
      return {
        en: "Vehicle is already in your vehicle list. Please check.",
        zh: "車輛已經存在",
      };

    case "PBP006":
      return {
        en: "Account is currently logged out as same account login from another device is detected.",
        zh: "帳號已從其他裝置登入。",
      };

    case "PBP007":
      return {
        en: "Invalid email or password",
        zh: "電郵或密碼無效",
      };

    case "PBP008":
      return {
        en: "The account has been locked or the activation process has not been completed. An email has been sent to your email address containing an activation link.",
        zh: "帳戶已被鎖或未完成啟動程序, 請先點擊我們已發給你的電郵中的啟動連結。",
      };

    case "PBP009":
      return {
        en: "Camera already disconnected",
        zh: "攝影機連線已中斷",
      };

    case "PBP010":
      return {
        en: "Cannot modify more than one LPN at a time",
        zh: "不能同時修改多個車牌號碼",
      };

    case "PBP011":
      return {
        en: "Invalid promo code",
        zh: "優惠碼無效",
      };

    case "PBP012":
      return {
        en: "The selected time slot has been fully booked, please select other time slot.",
        zh: "此預訂時段已滿, 請選擇其他時段。",
      };

    case "PBP013":
      return {
        en: `Invalid start time: booking is only available after ${minStartTime}`,
        zh: `開始時間無效： 最早可供預約時間為 ${minStartTime}`,
      };

    case "PBP014":
      return {
        en: "Invalid end time: end time must be greater than start time",
        zh: "結束時間無效： 結束時間須大於開始時間",
      };

    case "PBP015":
      return {
        en: `Invalid end time: booking should end after ${minEndTime}`,
        zh: `結束時間無效： 預約時段最早可結束於 ${minEndTime}`,
      };

    case "PBP016":
      return {
        en: "Please check the car park setting",
        zh: "請檢查停車場設定",
      };

    case "PBP017":
      return {
        en: `Invalid time slot: ${fromTime} - ${toTime}`,
        zh: `無效時段: ${fromTime} - ${toTime}`,
      };

    case "PBP018":
      return {
        en: `Missing date settings for car park  (${carparkId})`,
        zh: `停車場 (${carparkId}) 設定無效`,
      };

    case "PBP019":
      return {
        en: "Invalid booking period",
        zh: "所選時間無效",
      };

    case "PBP020":
      return {
        en: "Violate Prepayment Setting",
        zh: "不符合預繳設定",
      };

    case "PBP021":
      return {
        en: "Invalid booking period: booking period should be at least one month",
        zh: "所選時間無效： 須預約最少一個月",
      };

    case "PBP022":
      return {
        en: "Invalid booking period: booking period should be at max two years",
        zh: "所選時間無效： 可預約最多兩年",
      };

    case "PBP023":
      return {
        en: "Do Not Accept Upfront Payment",
        zh: "不接受預繳",
      };

    case "PBP024":
      return {
        en: "The selected time slot is not available. Please select other time slot.",
        zh: "所選時段暫時未能提供服務。請選擇其他時段。",
      };

    case "PBP025":
      return {
        en: "Car plate already changed once within the past one month",
        zh: "車牌在過去一個月內已曾經更改過",
      };

    case "PBP026":
      return {
        en: "Invalid start time",
        zh: "開始時間無效",
      };

    case "PBP027":
      return {
        en: "Invalid merchant settings (e.g. Missing compulsory params for external system connection)",
        zh: "Invalid merchant settings (e.g. Missing compulsory params for external system connection)",
      };

    case "PBP028":
      return {
        en: "Invalid Verification Code",
        zh: "手機驗證碼無效",
      };

    case "PBP029":
      return {
        en: "Incorrect Credit Card Information",
        zh: "信用卡資料錯誤",
      };

    case "PBP030":
      return {
        en: "Booking Request Expired",
        zh: "預訂請求已逾時",
      };

    case "PBP031":
      return {
        en: "Invoice Expired",
        zh: "預訂請求已逾時",
      };

    case "PBP032":
      return {
        en: "Vehicle is linked to valid current booking(s)",
        zh: "車牌號碼已連結現存有效預約",
      };

    case "PBP033":
      return {
        en: "You already have active booking of same service for selected vehicle.",
        zh: "You already have active booking of same service for selected vehicle.",
      };

    case "PBP034":
      return {
        en: `Minimum booking hour is ${hour} hour`,
        zh: `最少預約${hour}小時`,
      };

    case "PBP035":
      return {
        en: `Maximum booking hour is ${hour} hour`,
        zh: `最多預約${hour}小時`,
      };

    case "PBP036":
      return {
        en: "Invalid Old Password.",
        zh: "舊密碼不正確",
      };

    case "OCP10001":
      return {
        en: "Charge Point Not Exist",
        zh: "Charge Point Not Exist",
      };

    case "OCP10002":
      return {
        en: "Charge Point Charging",
        zh: "Charge Point Charging",
      };

    case "OCP10008":
      return {
        en: "Connector Error",
        zh: "Connector Error",
      };

    case "OCP10009":
      return {
        en: "Connector Not Found",
        zh: "Connector Not Found",
      };

    case "OCP10015":
      return {
        en: "Connector Not Available",
        zh: "Connector Not Available",
      };

    case "OCP10016":
      return {
        en: "Cable Not Connected / Connector Not Preparing",
        zh: "Cable Not Connected / Connector Not Preparing",
      };

    default:
      return "";
  }
}
