export const DASHBOARD = "/dashboard";
export const ADMINS = "/admins";
export const MY_ACCOUNT = "/myaccount";
export const SYSTEM_OPTIONS = "/systemOptions";
export const ADMIN_ROLES = "/adminRoles";
export const MERCHANTS = "/merchants";
export const CUSTOMERS = "/customers";
export const CUSTOMERSBATHCONFIRMATION = "/customers/batchConfirmation";
export const CAR_PARKS = "/carParks";
export const QUOTA_STATUS = "/quotaStatus";
export const LEASES = "/leases";
export const LEASESBATHCONFIRMATION = "/leases/batchConfirmation";
export const BOOKINGS = "/bookings";
export const INVOICES = "/invoices";
export const TRANSACTION = "/transaction";
export const REPORT_VERIFICATION_711 = "/transaction/reportVerification/7-11";
export const REPORT_VERIFICATION_CQM = "/transaction/reportVerification/CQM";
export const INVOICESBATHCONFIRMATION = "/invoices/batchConfirmation";
export const NOTIFICATIONS = "/notifications";
export const CONTENT_UPDATE = "/contentUpdate";
export const OCR = "/ocrRecords";
export const ACCOUNTING_REPORTS = "/accountingReports";
export const ACCOUNTINGREPORTSRENTALINCOME = "/accountingReports/rentalIncome";
export const ACCOUNTING_AUDIT = "accountingReports/report";
export const OPERATIONAL_PAGE = "/operationalPage";
export const CUSTOMIZED_MESSAGE = "/customizedMessage";
export const CUSTOMER_GROUP = "/customerGroup";
export const VEHICLE_GROUP = "/vehicleGroup";
export const ENQUIRY = "/enquiry";
export const ERROR_NOTIFICATIONS = "/errorNotifications";
export const PROMOTION = "/promotion";
export const EMAIL_TEMPLATE = "/emailTemplate";
export const AUDIT_REPORT = "accountingReports/audit";
