export default {
  app: {
    name: "pre-book-platform cms",
  },
  login: {
    username: "userName",
    email: "Email",
    password: "Password",
    login: "Login",
    logout: "Logout",
  },
  lang: {
    en: "English",
    zh: "Chinese",
  },
  VIPBlackList: {
    name: "VIP/Blacklist",
    action: "Action",
    csvUpload: "CSV upload",
    customerType: "Customer Type",
    type: "Type",
    carNumber: "Vehicle Number",
    remark: "Remark",
    addNew: "Add New",
    edit: "Edit",
    filterTitle: "Search/ filter",
    sure: "Are you sure? ",
    description: "Confirm to delete the record?",
    VIP: "VIP",
    blacklist: "Blacklist",
    LPN: "LPN",
    save: "Save",
    creditCard: "Credit Card",
    octopusCard: "Octopus Card",
  },
  Booking: {
    savePage: "Save this page",
    bookingSummary: "Booking Summary",
    bookingId: "Booking ID",
    leaseId: "Lease ID",
    evBookingId: "Ev Booking ID",
    evBookingRef: "Ev Booking Ref",
    email: "E-mail",
    registrationMark: "Registration mark",
    secondaryLPN: "Secondary LPN (up to 5)",
    carpark: "Carpark",
    bookingType: "Booking Type",
    vehicleType: "Vehicle Type",
    serviceType: "Service Type",
    service: "Service",
    parkingStatus: "Parking Status",
    bookingSetting: "Booking Setting",
    status: "Status",
    remark: "Confirmation remarks",
    parkingBay: "Parking Bay",
    group: "Group",
    floor: "Floor",
    code: "Code",
    reassign: "Reassign",
    manageParkingBay: "Manage Parking Bay",
    invoiceSummary: "Invoice Summary",
    prebook: "Prebook",
    casual: "Casual",
    permanent_change: "Permanent Change",
    temporary_change: "Temporary Change",
    service_change: "Service Change",
    temporary: "Temporary",
    confirmedByAdmin: "Confirmed By Admin",
    confirmParkingSlot: "Confirm Parking Bay",
    transmissionSummary: "Transmission Summary",
  },
  Button: {
    save: "Save",
    update: "Update",
    close: "Close",
    cancel: "Cancel",
    reset: "Reset",
    search: "Search",
    delete: "Delete",
  },
  Status: {
    pending: "Pending",
    confirmed: "Confirmed",
    cancelled: "Cancelled",
    completed: "Completed",
    error: "Error",
    booked: "Booked",
  },
  ServicePlan: {
    hourly: "Hourly",
    monthly: "Monthly",
    ev: "Ev Charge",
    recurring: "Monthly (Recurring)",
    timeshare: "Timeshare",
    flexibleRenewal: "Monthly (Renewal)",
  },
  Invoice: {
    status: "Status",
    type: "Type",
    issueDate: "Issue Date",
    expireDate: "Expire Date",
    amount: "Inv. Net amount",
    balance: "Balance",
  },
  Operational: {
    lowConfidence: "LOW CONFIDENCE",
    longStayCarpark: "LONG STAY CARPARK",
    longStayGate: "LONG STAY GATE",
    blockList: "BLOCK LIST",
    vipEntry: "VIP ENTRY",
    entry: "ENTRY",
    exit: "EXIT",
    longStay: "LONG STAY",
    pending: "PENDING",
    monthly: "Monthly",
    hourly: "Hourly",
    casual: "Casual",
    bookingList: "Booking List",
    inputCouponCode: "Input Coupon Code",
    waiveParkingFee: "Waive Parking Fee",
    updatePaymentRecord: "Update Payment Record",
    manual: "Manual",
    online: "Online",
    fapd: "FAPD",
    PrivateCar: "Private Car",
    Lorry: "Lorry",
    Motorcycle: "Motorcycle",
  },
  Form: {
    pleaseSelect: "Please select",
    required: "Required",
  },
  EmailTemplate: {
    one_time_monthly_booking_confirmation:
      "One Time Monthly Booking Confirmation",
    booking_confirmation: "Monthly parking service confirmation notice",
    one_time_payment_notice: "One-time payment notice",
    expiry_notice: "Monthly parking service expiry notice",
    monthly_renewal_notice: "Monthly parking service renewal notice",
    termination_notice: "Monthly parking service termination notice",
    hourly_termination_notice: "Hourly Termination Notice",
    receipt_casual: "Receipt Casual",
    "3_times_ocr_failure_notice": "3 times OCR failure notice",
    confirmation_for_resident_identity: "Confirmation for resident identity",
    confirmation_for_resident_identity_without_booking:
      "Confirmation for resident identity without booking",
    rejection_of_resident_identity: "Rejection of resident identity",
    confirmation_of_registration: "Confirmation of registration",
    registration_complete: "Your registration is complete",
    reset_password: "Reset password",
    personal_info_update_notice: "Successful updated account details",
    password_updated: "Your password has been updated",
    vehicle_change_notice: "Vehicle Change Notice",
    offline_payment_link: "Offline Payment Link",
  },
  errorRecord: {
    status: "Status",
    dateTime: "Date & Time",
    merchant: "Merchant",
    errorModule: "Error Module",
    errorType: "Error Type",
    errorDescription: "Error Description",
    carNumber: "Registration Mark",
    transmissionId: "Transmission ID",
  },
};
