import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useTheme } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import WarningIcon from "assets/images/danger.svg";
import GateIcon from "assets/images/gate.svg";
import VideoIcon from "assets/images/video.svg";
import DownloadIcon from "assets/images/download.png";
import UsersIcon from "assets/images/users.svg";
import EditIcon from "assets/images/edit.svg";

import {
  PrimaryButton,
  DangerButton,
  AddNewButton,
  LinkButton,
  CloseButton,
  IconTextButton,
  IconText,
  TextIconButton,
  EditButton,
  MoreButton,
  OutlineButton,
  TextButton,
  UnderlineButton,
} from "./CustomButton_styles";

function CustomButton({
  onClick = () => {},
  children,
  style,
  type = "button",
  variant = "primary",
  $hovercolor = "#000",
  disabled,
  to,
  text,
  bgColor,
  sm,
  lg,
  color,
  isActive = false,
}) {
  const theme = useTheme().operational;
  const props = {
    theme,
    onClick,
    children,
    style,
    type,
    variant,
    disabled,
    to,
    $sm: sm,
    $lg: lg,
    $isActive: isActive,
  };

  switch (variant) {
    case "danger":
      return <DangerButton {...props}>{children}</DangerButton>;
    case "close":
      return (
        <CloseButton {...props}>
          <CloseIcon />
        </CloseButton>
      );
    case "link":
      return (
        <LinkButton {...props} $hovercolor={$hovercolor || theme.primary}>
          {children}
        </LinkButton>
      );
    case "notification":
      return (
        <AddNewButton {...props}>
          <NotificationsNoneIcon />
        </AddNewButton>
      );
    case "warning":
      return (
        <IconTextButton
          {...props}
          $bgColor={theme.danger}
          color={theme.textDanger}
        >
          <img src={WarningIcon} alt="warning" />
          {text && <IconText>{text}</IconText>}
        </IconTextButton>
      );
    case "gate":
      return (
        <IconTextButton
          {...props}
          $bgColor={bgColor || theme.darkBlue}
          color={theme.white}
        >
          <img src={GateIcon} alt="gate" />
          {text && <IconText>{text}</IconText>}
        </IconTextButton>
      );
    case "video":
      return (
        <IconTextButton {...props} $bgColor={theme.gray} color={theme.black}>
          <img src={VideoIcon} alt="video" />
          {text && <IconText>{text}</IconText>}
        </IconTextButton>
      );
    case "export":
      return (
        <TextIconButton {...props} $bgColor={theme.primary} color={theme.black}>
          <IconText>下載</IconText>
          <img src={DownloadIcon} alt="download" />
        </TextIconButton>
      );
    case "user":
      return (
        <IconTextButton {...props} $bgColor={theme.primary} color={theme.black}>
          <img src={UsersIcon} alt="user" />
          {text && <IconText>{text}</IconText>}
        </IconTextButton>
      );
    case "edit":
      return (
        <EditButton {...props} color={theme.dark}>
          <img src={EditIcon} alt="edit" />
        </EditButton>
      );
    case "more":
      return (
        <MoreButton {...props}>
          <MoreVertIcon />
        </MoreButton>
      );
    case "text":
      return <TextButton {...props}>{children}</TextButton>;
    case "check":
      return (
        <EditButton {...props} color={theme.dark}>
          <CheckIcon />
        </EditButton>
      );
    case "arrowLeft":
      return (
        <EditButton {...props} color={theme.darkBlue}>
          <KeyboardArrowLeft />
        </EditButton>
      );
    case "arrowRight":
      return (
        <EditButton {...props} color={theme.darkBlue}>
          <KeyboardArrowRight />
        </EditButton>
      );
    case "underline":
      return (
        <UnderlineButton {...props} color={color || theme.dark}>
          {children}
        </UnderlineButton>
      );
    case "status":
      return (
        <OutlineButton
          {...props}
          $bgColor={bgColor}
          color={color || theme.dark}
        >
          {children}
        </OutlineButton>
      );
    case "cancel":
      return (
        <EditButton {...props} color={theme.dark}>
          <CloseIcon />
        </EditButton>
      );
    default:
      return (
        <PrimaryButton $bgColor={bgColor || theme.primary} {...props}>
          {children}
        </PrimaryButton>
      );
  }
}

export default CustomButton;
