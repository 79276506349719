import { useCallback, useState } from "react";
import { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import useWindowSize from "hooks/windowSizeHook";

const slideMenuWidth = 260;

const useAppModel = (savedModelInstance = {}) => {
  const { width } = useWindowSize();

  const [appIcon, setAppIcon] = useState(savedModelInstance?.appIcon);
  const [headerHeight, setHeaderHeight] = useState(50);
  const [showMenu, setShowMenu] = useState(!(width <= 980));
  const [errorRecordCount, setErrorRecordCount] = useState(0);

  const onSaveInstanceState = useCallback(
    () => ({
      appIcon,
    }),
    [appIcon]
  );

  const toggleMenu = useCallback((state) => {
    setShowMenu(state);
  }, []);

  function changeErrorRecordCount(e) {
    setErrorRecordCount(e);
  }

  async function handleUploadFile(token, file) {
    const { url } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/media/upload`,
      null,
      token
    );
    try {
      const { data } = await APIHelper("POST", url.toString(), file, false, {
        accept: "application/json, text/plain, /",
        Authorization: `Bearer ${token}`,
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    appIcon,
    onSaveInstanceState,
    setHeaderHeight,
    headerHeight,
    slideMenuWidth,
    setShowMenu,
    showMenu,
    toggleMenu,
    handleUploadFile,
    errorRecordCount,
    changeErrorRecordCount,
  };
};

const AppModel = BaseModel(useAppModel, "AppModel");

export { useAppModel };
export default AppModel;
