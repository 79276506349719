import { useCallback, useState } from "react";
import { useTheme } from "@mui/system";
import { uniqueId } from "lodash";
import { useTranslation } from "react-i18next";
import BaseModel from "base/BaseModel";

const alertTypes = [
  // variants
  "success",
  "error",
  "warning",
  "info",
  "delete",
  "unsaved",
  "customize",
  "message",

  // functions
  "close",
  "showValidationMessage",
];
const toastTypes = [
  "success",
  "error",
  "warning",
  "info",

  // functions
  "closeAll",
];

const usePopUpModel = (saveModelInstance = {}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [props, setProps] = useState({});
  const [toastList, setToastList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const changeIsLoading = useCallback((e) => {
    setIsLoading(e);
  }, []);

  const changeInputValue = useCallback((e) => {
    setInputValue(e);
  }, []);

  const changeValidationMessage = useCallback((e) => {
    setValidationMessage(e);
  }, []);

  function toggleAlert(alertProps, type) {
    if (alertProps) {
      setOpen(true);
      setProps({ ...alertProps, type });
      return;
    }

    changeValidationMessage("");
    changeInputValue("");
    setProps({});
    setOpen(false);
  }

  const toggleToast = useCallback((id, text, inputType) => {
    if (id === "closeAll") {
      setToastList([]);
    }

    if (!id) {
      setToastList((prev) => [
        ...prev,
        { text, id: uniqueId(), type: inputType },
      ]);
      return;
    }

    setToastList((prev) => {
      const filteredArray = prev.filter((v) => v.id !== id);
      return filteredArray;
    });
  }, []);

  const ALERT = alertTypes.reduce((acc, type) => {
    if (type === "close") {
      return {
        ...acc,
        [type]: () => toggleAlert(null),
      };
    }

    if (type === "showValidationMessage") {
      return {
        ...acc,
        [type]: (e) => changeValidationMessage(e),
      };
    }

    return {
      ...acc,
      [type]: (e) => toggleAlert(e, type),
    };
  }, {});

  const TOAST = toastTypes.reduce((acc, type) => {
    if (type === "closeAll") {
      return {
        ...acc,
        [type]: () => toggleToast("closeAll"),
      };
    }
    return {
      ...acc,
      [type]: (text) => toggleToast(null, text, type),
    };
  }, {});

  return {
    t,
    theme,

    open,
    props,
    inputValue,
    changeInputValue,
    validationMessage,
    changeValidationMessage,
    isLoading,
    changeIsLoading,

    ALERT,
    toggleAlert,

    TOAST,
    toggleToast,
    toastList,
  };
};

const PopUpModel = BaseModel(usePopUpModel, "PopUpModel");

export { usePopUpModel };
export default PopUpModel;
