import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > img {
    width: auto;
    height: 600px;
  }

  > span {
    font-size: 40px;
  }
`;
