import alertWarningIcon from "assets/images/warningModal.svg";
import alertDeleteIcon from "assets/images/Delete.png";
import alertSuccessIcon from "assets/images/alertSuccess.svg";
import alertErrorIcon from "assets/images/alertError.svg";
import alertInfoIcon from "assets/images/alertInfo.svg";

function typeAttribute(type, theme) {
  switch (type) {
    case "success":
      return {
        icon: alertSuccessIcon,
        color: theme.success,
        title: "",
        confirmText: "Confirm",
        closeText: "Back",
        text: "",
      };

    case "error":
      return {
        icon: alertErrorIcon,
        title: "",
        confirmText: "Confirm",
        closeText: "Back",
        text: "",
      };

    case "info":
      return {
        icon: alertInfoIcon,
        color: theme.info,
        title: "",
        confirmText: "Confirm",
        closeText: "Back",
        text: "",
      };

    case "warning":
      return {
        icon: alertWarningIcon,
        color: theme.warning,
        title: "Are you sure?",
        confirmText: "Confirm",
        closeText: "Back",
        text: "",
      };

    case "unsaved":
      return {
        icon: alertWarningIcon,
        color: theme.warning,
        title: "Unsaved Changes",
        confirmText: "Save changes",
        closeText: "Back",
        text: "You are about to leave this page without saving.\nAll changes will be lost.\nDo you really want to leave without saving?",
      };

    case "delete":
      return {
        icon: alertDeleteIcon,
        color: theme.warning,
        title: "Are you sure?",
        confirmText: "Delete",
        closeText: "Cancel",
        text: "Confirm to delete the record?",
      };
    case "message":
      return {
        icon: alertWarningIcon,
        color: theme.warning,
        text: "",
      };
    case "customize":
    default:
      return {
        icon: "",
        color: theme.primary,
        confirmText: "Confirm",
        closeText: "Back",
        text: "",
      };
  }
}

export { typeAttribute };
