import React, { useContext, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import AppModel from "models/appModel";
import UserModel from "models/userModel";
import ComponentImg from "assets/images/Account.png";
import Logo from "assets/images/logo_white.png";
import {
  Container,
  RightRow,
  LeftRow,
  LogOut,
  MenuLogo,
  LogoImg,
  Name,
  CustomerButton,
  MenuButton,
  Divider,
} from "./Header.styles";

export default function Header() {
  const theme = useTheme();
  const navigate = useNavigate();
  const headerRef = useRef();
  const { setHeaderHeight, showMenu, toggleMenu } = useContext(
    AppModel.Context
  );
  const { logout, userData } = useContext(UserModel.Context);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight || 50);
    }
  }, [headerRef?.current?.clientHeight, setHeaderHeight]);

  const { firstName, lastName, Merchant } = userData || {};
  const { name = {} } = Merchant || {};

  return (
    <Container $backgroundColor={theme.primary} ref={headerRef}>
      <LeftRow>
        <MenuLogo>
          <LogoImg src={Logo} />
        </MenuLogo>
        <MenuButton
          type="button"
          onClick={() => toggleMenu(!showMenu)}
          $showMenu={showMenu}
        >
          {showMenu ? <ArrowLeftIcon /> : <ArrowRightIcon />}
        </MenuButton>
      </LeftRow>
      <RightRow>
        <div>
          <Name>{name?.en}</Name>
        </div>
        <Divider>|</Divider>
        <div>
          <CustomerButton type="button" onClick={() => navigate("myaccount")}>
            <img alt="Component" src={ComponentImg} />

            <Name>
              {firstName}
              {"\n"}
              {lastName}
            </Name>
          </CustomerButton>
        </div>

        <div>
          <LogOut onClick={() => logout(navigate)}>Log Out</LogOut>
        </div>
      </RightRow>
    </Container>
  );
}
