import React, { useContext, useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { isArray } from "lodash";
import { TreeView } from "@mui/x-tree-view/TreeView";
import AppModel from "models/appModel";
import UserModel from "models/userModel";
import { useLocation } from "react-router-dom";
import Logo from "assets/images/logo.png";
import {
  Wrapper,
  OverLayer,
  SlideMenuLogo,
  LogoImg,
  MenuBody,
  MenuFooter,
} from "./SlideMenu.styles";
import RenderStyledTreeItem from "./RenderStyledTreeItem";
import {
  SLIDE_MENU_ITEMS,
  MONTHLY_DISABLED_LIST,
  NECESSARY_PERMISSION,
} from "./constants";

export default function SlideMenu() {
  const location = useLocation();

  const { showMenu, toggleMenu, slideMenuWidth } = useContext(AppModel.Context);
  const { userData, isMonthlyEnable, isSystem } = useContext(UserModel.Context);
  const { level, Permissions = [], AdminGroup = {} } = userData || {};
  const { isAllGranted = false } = AdminGroup || {};

  const grantedMenuItems = useMemo(
    () =>
      SLIDE_MENU_ITEMS.filter((menuItem) => {
        const {
          children = [],
          level: menuItemLevel = [],
          name,
          resource = [],
        } = menuItem || {};

        if (isSystem) {
          return true;
        }

        const isIncludeMonthlyDisabledList =
          MONTHLY_DISABLED_LIST.includes(name);

        const isAllChildrenIncludeMonthlyDisabledList =
          children.length > 0 &&
          children.filter((c) => MONTHLY_DISABLED_LIST.includes(c.name))
            .length === children.length;

        if (
          !isMonthlyEnable &&
          (isIncludeMonthlyDisabledList ||
            isAllChildrenIncludeMonthlyDisabledList)
        ) {
          return false;
        }

        const isAllChildrenNoReadPermission = children.every(
          (e) =>
            Permissions.findIndex(
              (permission) =>
                e.resource.findIndex((r) => r === permission.resource) !== -1 &&
                permission.actions.includes("read")
            ) === -1
        );

        const isAllChildrenNoNecessaryPermission = children.every((c) =>
          c.resource.every(
            (r) =>
              !NECESSARY_PERMISSION.find(
                (necessaryPer) =>
                  necessaryPer.resource === r &&
                  necessaryPer.actions.includes("read")
              )
          )
        );

        if (isArray(children) && children.length > 0) {
          if (
            !isAllGranted &&
            isAllChildrenNoReadPermission &&
            isAllChildrenNoNecessaryPermission
          ) {
            return false;
          }

          return !children.every((e) => !e.level.includes(level));
        }

        const grantedForReadPermission = Permissions.find(
          (permission) =>
            resource.findIndex((r) => r === permission.resource) !== -1 &&
            permission.actions.includes("read")
        );

        if (
          !isAllGranted &&
          children.length === 0 &&
          !grantedForReadPermission &&
          !resource.includes("*")
        ) {
          return false;
        }

        return menuItemLevel.includes(level);
      }),
    [userData]
  );

  const defaultExpanded = grantedMenuItems.find(
    (e) =>
      e.path === location.pathname ||
      (isArray(e.children)
        ? e.children.findIndex((v) => v.path === location.pathname) !== -1
        : false)
  )?.name;

  const [expanded, setExpanded] = useState([defaultExpanded]);

  const handleSelect = (event, nodeIds) => {
    if (expanded.includes(nodeIds)) {
      return setExpanded(expanded.filter((e) => e !== nodeIds));
    }

    const array = [...expanded];
    array.push(nodeIds);
    return setExpanded(array);
  };

  return (
    <>
      <OverLayer $showMenu={showMenu} onClick={() => toggleMenu(false)} />
      <Wrapper $showMenu={showMenu} $slideMenuWidth={slideMenuWidth}>
        <SlideMenuLogo>
          <LogoImg src={Logo} />
        </SlideMenuLogo>
        <MenuBody>
          <TreeView
            aria-label="customized"
            defaultExpandIcon={<ChevronRightIcon />}
            defaultCollapseIcon={<ExpandMoreIcon />}
            expanded={expanded}
            onNodeSelect={handleSelect}
          >
            {level &&
              grantedMenuItems.map((item) => (
                <RenderStyledTreeItem
                  key={item.name}
                  item={item}
                  level={level}
                  isMonthlyEnable={isMonthlyEnable}
                  isAllGranted={isAllGranted}
                  Permissions={Permissions}
                />
              ))}
          </TreeView>
        </MenuBody>
        <MenuFooter>Let's Park v20230523.01</MenuFooter>
      </Wrapper>
    </>
  );
}
