import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "components/common/CustomButton";
import NoFoundImg from "assets/images/noFound.png";
import { Container } from "./NotFound.style";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Container className="mainContainer">
      <img src={NoFoundImg} alt="" />
      <Button
        onClick={() => navigate(-1)}
        style={{ marginTop: 40, fontSize: "1.5rem" }}
      >
        Go Back
      </Button>
    </Container>
  );
}
