import React, { useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useSpring, animated } from "@react-spring/web";
import Collapse from "@mui/material/Collapse";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view";
import { useLocation, useNavigate } from "react-router-dom";
import AppModel from "models/appModel";
import { FlexDiv } from "base/BaseStyle";
import useWindowSize from "hooks/windowSizeHook";
import { SubTreeButton, ErrorRecordCount } from "./TreeItem.styles";
import { MONTHLY_DISABLED_LIST, NECESSARY_PERMISSION } from "./constants";

function TransitionComponent(props) {
  const { in: isIn } = props;
  const style = useSpring({
    config: { duration: 600 },
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: isIn ? 1 : 0,
      transform: `translate3d(${isIn ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

// const StyledTreeItem = styled((props) => (
//   <TreeItem {...props} TransitionComponent={TransitionComponent} />
// ))(({ theme, $currentPath, path, $childrenArray = [] }) => {
//   const childrenPath = $childrenArray.map((e) => e?.path);
//   const isFocused =
//     $currentPath === path || childrenPath.includes($currentPath);
//   return {
//     [`&.${treeItemClasses.root}`]: { width: "100%", position: "relative" },
//     [`& .${treeItemClasses.content}`]: {
//       borderRadius: "0px",
//       marginTop: "10px",
//       padding: "10px",
//       transition: "300ms all ease",
//       backgroundColor: isFocused && `${theme.secondary} !important`,
//       boxSizing: "border-box",
//       "&:hover": {
//         backgroundColor: `${theme.secondary} !important`,
//         color: theme.primary,
//         "& p": { color: theme.primary },
//       },
//       "& img ,svg": { marginTop: "3px" },
//       "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
//         backgroundColor: "transparent",
//       },
//       "& img": {
//         filter:
//           isFocused &&
//           "invert(70%) sepia(60%) saturate(3053%) hue-rotate(182deg) brightness(92%) contrast(93%)",
//       },
//       "& p": { color: isFocused ? theme.primary : theme.gray, lineHeight: 1 },
//       "& path": { fill: isFocused && theme.primary },
//     },
//     [`& .${treeItemClasses.iconContainer}`]: {
//       position: "absolute",
//       right: 0,
//       "& .close": { opacity: 0.3 },
//       "& svg": {
//         fill: theme.primary,
//         color: isFocused ? theme.primary : "#BABABA",
//       },
//     },
//     [`& .${treeItemClasses.group}`]: { width: "100%", marginLeft: 0 },
//   };
// });

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme, currentpath, path, childrenarray = [] }) => {
  const childrenPath = childrenarray.map(
    ({ path: childrenpath }) => childrenpath
  );
  const isFocused = currentpath === path || childrenPath.includes(currentpath);
  return {
    [`&.${treeItemClasses.root}`]: {
      width: "calc(100% - 20px)",
      position: "relative",
    },
    [`& .${treeItemClasses.content}`]: {
      borderRadius: "10px",
      marginTop: "10px",
      padding: "10px",
      transition: "300ms all ease",
      backgroundColor: isFocused && `${theme.secondary} !important`,
      boxSizing: "border-box",
      color: theme.primary,

      "&:hover": {
        backgroundColor: `${theme.secondary} !important`,
        color: theme.primary,
        "& p": { color: theme.primary },
        "& img": {
          filter:
            "invert(63%) sepia(73%) saturate(460%) hue-rotate(129deg) brightness(87%) contrast(89%)",
        },
      },
      "& img ,svg": { marginTop: "3px" },
      "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
        backgroundColor: "transparent",
      },
      "& img": {
        filter:
          isFocused &&
          "invert(63%) sepia(73%) saturate(460%) hue-rotate(129deg) brightness(87%) contrast(89%)",
      },
      "& p": { color: theme.primary, lineHeight: 1 },
      "& path": { fill: isFocused && theme.primary },
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      position: "absolute",
      right: 0,
      "& .close": {
        opacity: 0.3,
      },
      "& svg": {
        fill: theme.primary,
        color: isFocused ? theme.primary : "#BABABA",
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 0,
      width: "calc(100% - 10px)",
    },
  };
});

export default function RenderStyledTreeItem({
  item,
  level,
  isMonthlyEnable,
  isAllGranted,
  Permissions,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { name, path, children, icon } = item || {};
  const { toggleMenu, errorRecordCount } = useContext(AppModel.Context);
  const theme = useTheme();

  const currentPath = location.pathname
    ? `/${location.pathname.split("/")?.[1]}`
    : "";

  return (
    <StyledTreeItem
      key={name}
      nodeId={name}
      label={
        <FlexDiv style={{ whiteSpace: "nowrap" }}>
          {icon}
          {name}
        </FlexDiv>
      }
      currentpath={currentPath}
      path={path}
      onClick={() => {
        if (!path) return;
        navigate(path);
        if (width <= 980) {
          toggleMenu(false);
        }
      }}
      childrenarray={children}
    >
      {Array.isArray(children)
        ? children
            .filter((c) => {
              if (level === "system") {
                return true;
              }

              const { resource = [] } = c || {};
              const isIncludeMonthlyDisabledList =
                MONTHLY_DISABLED_LIST.includes(c.name);
              if (!isMonthlyEnable && isIncludeMonthlyDisabledList) {
                return false;
              }

              const isNecessaryPermission = resource.every((r) =>
                NECESSARY_PERMISSION.find(
                  (necessaryPer) => necessaryPer.resource === r
                )
              );

              const haveReadPermission = Permissions.find(
                (permission) =>
                  resource.findIndex((r) => r === permission.resource) !== -1 &&
                  permission.actions.includes("read")
              );

              if (
                !isNecessaryPermission &&
                !isAllGranted &&
                !haveReadPermission
              ) {
                return false;
              }

              return c.level.includes(level);
            })
            .map((child) => (
              <SubTreeButton
                theme={theme}
                $currentPath={currentPath}
                path={child.path}
                type="button"
                key={child.name}
                onClick={() => {
                  navigate(child.path);
                  if (width <= 980) {
                    toggleMenu(false);
                  }
                }}
              >
                {child.name}
                {child.name === "Error Notifications" && (
                  <ErrorRecordCount badgeContent={errorRecordCount} />
                )}
              </SubTreeButton>
            ))
        : null}
    </StyledTreeItem>
  );
}
