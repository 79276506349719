import styled from "styled-components";
import { Link } from "react-router-dom";

const BasicButton = styled.button`
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  transition: 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    transition: 300ms;
  }

  &:disabled {
    background-color: #dedede;
    color: #fff;
    &:hover {
      box-shadow: none;
    }
  }
`;

const PrimaryButton = styled(BasicButton)`
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
`;

const SecondaryButton = styled(BasicButton)`
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.primary};
`;

const NavigateButton = styled(Link)`
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  transition: 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  text-decoration: none;

  &:hover {
    box-shadow: 0 0 10px 5px #dedede;
    transition: 300ms;
  }

  &:disabled {
    background-color: #dedede;
    &:hover {
      box-shadow: none;
    }
  }
`;

const CloseButton = styled(BasicButton)`
  position: absolute;
  right: 5px;
  top: -10px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.primary};
  }
`;

const EditButton = styled(BasicButton)`
  background-color: #fff;
  padding: 5px 5px 5px 5px;
  margin: auto;
  svg {
    color: ${({ theme }) => `${theme.primary}`};
    height: ${({ $iconsize }) => ($iconsize?.height ? $iconsize.height : 30)};
    width: ${({ $iconsize }) => ($iconsize?.width ? $iconsize.width : 30)};
  }
  box-shadow: none;
`;

const AddNewButton = styled(BasicButton)`
  padding: 0;
  border-radius: 0.25rem;
  height: fit-content;
  // aspect-ratio: 1;
  display: flex;
  background-color: ${({ theme }) => `${theme.primary}`};
  color: #fff;

  &:disabled {
    background-color: #dedede;
    &:hover {
      box-shadow: none;
    }
  }

  svg {
    height: 30px;
    width: 30px;
  }
`;

const ReverseButton = styled.button`
  padding: 0;
  border-radius: 0.25rem;
  height: fit-content;
  // aspect-ratio: 1;
  display: flex;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.primary};
  padding: 5px;
  transition: all 300ms;

  img {
    height: 20px;
    width: 20px;
  }
`;

const SortButton = styled.button`
  padding: 0;
  border-radius: 0.25rem;
  height: fit-content;
  //aspect-ratio: 1;
  // background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.primary};

  div {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  span {
    margin-right: 10px;
    font-weight: bold;
  }

  img {
    height: 20px;
    width: 20px;
  }
`;

const LinkButton = styled.button`
  color: ${({ theme }) => theme.primary};
  font-size: 1rem;
  border-bottom: 1px solid transparent;
  transition: all 300ms ease-in-out;

  &:hover {
    border-bottom: ${({ $hovercolor }) => `1px solid ${$hovercolor}`};
  }
`;

const DeleteButton = styled.button`
  color: ${({ theme }) => theme.danger};
  font-size: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.danger};
  transition: all 300ms ease-in-out;
  font-weight: bold;
`;

const QuestionMaskButton = styled.button`
  margin-left: 5px;
  height: 25px;
  width: 25px;
  background-color: ${({ theme }) => theme.darkBlue};
  color: #fff;
  border-radius: 100%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  @media (max-width: 980px) {
    height: 25px;
    width: 25px;
  }
`;

const TextButton = styled(BasicButton)`
  background-color: transparent;
  border-bottom: 1px solid ${({ theme, color = "primary" }) => theme?.[color]};
  box-shadow: none;
  color: ${({ color = "primary", theme }) => theme?.[color]};
  padding: 0;
  border-radius: unset;

  &:hover {
    box-shadow: none;
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.3;
    color: ${({ color = "primary", theme }) => theme?.[color]};
  }
`;

  const IconButton = styled(BasicButton)`
  background-color: transparent;
  padding: 5px 5px 5px 5px;
  margin: auto;
  border: ${({ theme }) => `1px solid ${theme.primary}`};
  svg {
    color: ${({ theme }) => `${theme.primary}`};
    height: ${({ iconsize }) => (iconsize?.height ? iconsize.height : 30)};
    width: ${({ iconsize }) => (iconsize?.width ? iconsize.width : 30)};
  }
  box-shadow: none;
`;

export {
  PrimaryButton,
  SecondaryButton,
  AddNewButton,
  LinkButton,
  ReverseButton,
  SortButton,
  NavigateButton,
  EditButton,
  CloseButton,
  DeleteButton,
  QuestionMaskButton,
  TextButton,
  IconButton,
};
