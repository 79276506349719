import React from "react";
import {
  DASHBOARD,
  ADMINS,
  MY_ACCOUNT,
  SYSTEM_OPTIONS,
  ADMIN_ROLES,
  MERCHANTS,
  CUSTOMERS,
  CAR_PARKS,
  QUOTA_STATUS,
  LEASES,
  BOOKINGS,
  INVOICES,
  TRANSACTION,
  NOTIFICATIONS,
  CONTENT_UPDATE,
  OCR,
  ACCOUNTING_REPORTS,
  OPERATIONAL_PAGE,
  CUSTOMIZED_MESSAGE,
  CUSTOMER_GROUP,
  VEHICLE_GROUP,
  ENQUIRY,
  ERROR_NOTIFICATIONS,
  PROMOTION,
  EMAIL_TEMPLATE,
} from "./paths";

const Dashboard = React.lazy(() => import("views/Dashboard"));
const Admins = React.lazy(() => import("views/Admins"));
const MyAccount = React.lazy(() => import("views/MyAccount"));
const Merchants = React.lazy(() => import("views/Merchants"));
const AdminRoles = React.lazy(() => import("views/AdminRoles"));
const SystemOptions = React.lazy(() => import("views/SystemOptions"));
const Customers = React.lazy(() => import("views/Customers/Customer"));
const CarParks = React.lazy(() => import("views/CarParks"));
const QuotaStatus = React.lazy(() => import("views/QuotaStatus"));
const Leases = React.lazy(() => import("views/Lease&Booking/Leases"));
const Bookings = React.lazy(() => import("views/Lease&Booking/Bookings"));
const Invoices = React.lazy(() => import("views/Invoices"));
const Transaction = React.lazy(() => import("views/Transaction"));
const Notifications = React.lazy(() => import("views/Notifications"));
const ContentUpdate = React.lazy(() => import("views/ContentUpdate"));
const OCRRecords = React.lazy(() => import("views/OCR"));
const AccountingReports = React.lazy(() => import("views/AccountingReports"));
const OperationalPage = React.lazy(() => import("views/OperationalPage"));
const CustomizedMessagePage = React.lazy(() =>
  import("views/CustomizedMessage")
);
const CustomerGroup = React.lazy(() => import("views/CustomerGroup"));
const VehicleGroup = React.lazy(() => import("views/VehicleGroup"));
const Enquiry = React.lazy(() => import("views/Enquiry"));
const ErrorNotifications = React.lazy(() => import("views/ErrorNotifications"));
const Promotion = React.lazy(() => import("views/Promotion/index"));
const EmailTemplate = React.lazy(() => import("views/EmailTemplate"));

const MainRoute = [
  {
    component: Dashboard,
    path: DASHBOARD,
  },
  { component: Admins, path: ADMINS },
  { component: MyAccount, path: MY_ACCOUNT },
  { component: SystemOptions, path: SYSTEM_OPTIONS },
  { component: AdminRoles, path: ADMIN_ROLES },
  {
    component: Merchants,
    path: MERCHANTS,
  },
  {
    component: Customers,
    path: `${CUSTOMERS}/*`,
  },
  {
    component: CarParks,
    path: `${CAR_PARKS}/*`,
  },
  {
    component: QuotaStatus,
    path: QUOTA_STATUS,
  },
  {
    component: Leases,
    path: `${LEASES}/*`,
  },
  {
    component: Bookings,
    path: `${BOOKINGS}/*`,
  },
  {
    component: Invoices,
    path: `${INVOICES}/*`,
  },
  {
    component: Transaction,
    path: `${TRANSACTION}/*`,
  },
  {
    component: Notifications,
    path: `${NOTIFICATIONS}/*`,
  },
  {
    component: ContentUpdate,
    path: `${CONTENT_UPDATE}/*`,
  },
  {
    component: OCRRecords,
    path: `${OCR}/*`,
  },
  {
    component: AccountingReports,
    path: `${ACCOUNTING_REPORTS}/*`,
  },
  {
    component: OperationalPage,
    path: `${OPERATIONAL_PAGE}/*`,
  },
  {
    component: CustomizedMessagePage,
    path: `${CUSTOMIZED_MESSAGE}/*`,
  },
  {
    component: CustomerGroup,
    path: `${CUSTOMER_GROUP}/*`,
  },
  {
    component: VehicleGroup,
    path: `${VEHICLE_GROUP}/*`,
  },
  {
    component: Enquiry,
    path: `${ENQUIRY}/*`,
  },
  {
    component: ErrorNotifications,
    path: `${ERROR_NOTIFICATIONS}/*`,
  },
  {
    component: Promotion,
    path: `${PROMOTION}/*`,
  },
  {
    component: EmailTemplate,
    path: `${EMAIL_TEMPLATE}/*`,
  },
];

export default MainRoute;
