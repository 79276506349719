import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import { useTheme } from "@mui/system";
import { StyledP } from "base/BaseStyle";
import { ErrorMessageRow } from "../BaseAlert/styles";

export default function ErrorMessage({ message }) {
  const theme = useTheme();

  return (
    <ErrorMessageRow>
      <WarningIcon style={{ color: theme.danger }} />
      <StyledP color="danger" fontSize="14">
        {message}
      </StyledP>
    </ErrorMessageRow>
  );
}
