import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "operationalLog";

const useOperationalLogModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, getSchema, deleteOne } =
    BaseActions(module, { Merchant, isSystem, token });

  async function exportToCSV(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/action/export/csv`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        false,
        headers,
        true
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getSchema,
    deleteOne,
    exportToCSV,
  };
};

const OperationalLogModel = BaseModel(
  useOperationalLogModel,
  "OperationalLogModel"
);

export { useOperationalLogModel };
export default OperationalLogModel;
