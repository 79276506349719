import React from "react";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import UndoIcon from "@mui/icons-material/Undo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import { StyledSpan } from "base/BaseStyle";
import {
  PrimaryButton,
  SecondaryButton,
  AddNewButton,
  LinkButton,
  ReverseButton,
  SortButton,
  NavigateButton,
  EditButton,
  CloseButton,
  DeleteButton,
  QuestionMaskButton,
  TextButton,
  IconButton,
} from "./CustomButton_styles";
import LoadingSpinner from "../LoadingSpinner";

function CustomButton({
  onClick = () => {},
  children,
  style,
  type = "button",
  variant = "primary",
  $hovercolor = "#000",
  customactionheaderbutton = () => {},
  disabled,
  to,
  text,
  $iconsize = {},
  loading = undefined,
}) {
  const theme = useTheme();
  const props = {
    theme,
    onClick,
    children,
    style,
    type,
    variant,
    disabled,
    to,
    $iconsize,
    loading,
    text,
    $hovercolor,
  };

  switch (variant) {
    case "copy":
      return (
        <AddNewButton {...props}>
          <ContentCopyIcon />
        </AddNewButton>
      );
    case "confirm":
      return (
        <AddNewButton {...props}>
          <CheckIcon />
        </AddNewButton>
      );
    case "refresh":
      return (
        <PrimaryButton {...props}>
          {text && <StyledSpan color="white">{text}</StyledSpan>}
          <CachedIcon style={{ marginLeft: 10 }} />
        </PrimaryButton>
      );
    case "search":
      return (
        <EditButton {...props}>
          <SearchIcon />
        </EditButton>
      );
    case "download":
      return (
        <EditButton {...props}>
          <FileDownloadIcon />
          {text && (
            <StyledSpan color="primary" style={{ textDecoration: "underline" }}>
              {text}
            </StyledSpan>
          )}
        </EditButton>
      );
    case "close":
      return (
        <CloseButton {...props}>
          <CloseIcon />
        </CloseButton>
      );
    case "more":
      return (
        <EditButton {...props}>
          <MoreVertIcon />
        </EditButton>
      );
    case "delete":
      return (
        <EditButton {...props}>
          <DeleteIcon />
        </EditButton>
      );
    case "edit":
      return (
        <EditButton {...props}>
          <EditIcon />
        </EditButton>
      );
    case "navigate":
      return (
        <NavigateButton target="_blank" to={to} {...props}>
          {children}
        </NavigateButton>
      );
    case "sort":
      return (
        <SortButton {...props}>
          {typeof customactionheaderbutton === "function" &&
            customactionheaderbutton()}
        </SortButton>
      );
    case "reverse":
      return (
        <ReverseButton {...props}>
          <FontAwesomeIcon
            icon="fa-solid fa-right-left"
            style={{ transform: "rotateZ(90deg)", width: 20, height: 20 }}
          />
        </ReverseButton>
      );
    case "link":
      return (
        <LinkButton {...props} $hovercolor={$hovercolor || theme.primary}>
          {children}
        </LinkButton>
      );
    case "add":
      return (
        <AddNewButton {...props}>
          <AddIcon />
        </AddNewButton>
      );
    case "remove":
      return (
        <AddNewButton {...props}>
          <RemoveIcon />
        </AddNewButton>
      );

    case "undo":
      return (
        <AddNewButton {...props}>
          <UndoIcon />
        </AddNewButton>
      );
    case "modal_delete":
      return <DeleteButton {...props}>{children}</DeleteButton>;
    case "secondary":
      return <SecondaryButton {...props}>{children}</SecondaryButton>;
    case "questionMark":
      return <QuestionMaskButton {...props}>?</QuestionMaskButton>;
    case "arrow_left":
      return (
        <AddNewButton>
          <ArrowBackIcon />
        </AddNewButton>
      );
    case "arrow_right":
      return (
        <AddNewButton>
          <ArrowForwardIcon />
        </AddNewButton>
      );
    case "text":
      return <TextButton {...props}>{children}</TextButton>;
    case "filter_search":
      return (
        <IconButton {...props}>
          <SearchIcon />
        </IconButton>
      );
    case "primary":
    // fall through
    default:
      return (
        <PrimaryButton {...props}>
          {children}
          {!!loading && (
            <LoadingSpinner style={{ marginLeft: 10 }} width="30" height="30" />
          )}
        </PrimaryButton>
      );
  }
}

export default CustomButton;
