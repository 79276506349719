import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "enquiry";

const useEnquiryModel = () => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, getSchema, deleteOne } =
    BaseActions(module, { Merchant, isSystem, token });

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getSchema,
    deleteOne,
  };
};

const EnquiryModel = BaseModel(useEnquiryModel, "EnquiryModel");

export { useEnquiryModel };

export default EnquiryModel;
