import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "collectionMethod";

const useCollectionMethodModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, deleteOne } = BaseActions(
    module,
    { Merchant, isSystem, token }
  );

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    deleteOne,
  };
};

const CollectionMethodModel = BaseModel(
  useCollectionMethodModel,
  "CollectionMethodModel"
);

export { useCollectionMethodModel };
export default CollectionMethodModel;
