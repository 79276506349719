import qs from "qs";
import APIHelper from "./APIHelper";

export const DEFAULT_HEADERS = {
  Accept: "application/json, text/plain, /",
  "Content-Type": "application/json",
};

export const APIRequestGenerator = (rawURL, params = null, token = null) => {
  let url = new URL(rawURL);
  if (params && typeof params === "object") {
    // Object.keys(params).forEach((key) => {
    //   url.searchParams.set(key, params[key]);
    // });
    url.search = qs.stringify(params, { arrayFormat: "brackets" });
  }
  const headers = { ...DEFAULT_HEADERS };
  if (token) headers.Authorization = `Bearer ${token}`;
  // if (token) url.searchParams.set("token", token);
  url = url.toString();
  return { url, headers };
};

const BaseActions = (
  module,
  { Merchant = undefined, isSystem = false, token = null }
) => {
  async function getList(params, inputToken = token) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}`,
      isSystem ? params : { Merchant, ...params },
      inputToken
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getOne(id, params, inputToken = token) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}`,
      isSystem ? params : { Merchant, ...params },
      inputToken
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      const { records } = response || {};
      const [record = {}] = records || [];

      return Promise.resolve(record);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createOne(data, inputToken = token) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}`,
      null,
      inputToken
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Merchant, ...data },
        true,
        headers
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateOne(id, data, inputToken = token) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}`,
      null,
      inputToken
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getSchema() {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/schema`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      const { data = {} } = response || {};
      const { schema = {} } = data || {};

      return Promise.resolve(schema);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function deleteOne(id, inputToken = token) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}`,
      null,
      inputToken
    );
    try {
      const response = await APIHelper(
        "DELETE",
        url.toString(),
        null,
        true,
        headers
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    createOne,
    getList,
    getOne,
    updateOne,
    deleteOne,
    getSchema,
  };
};

export default BaseActions;
