import React, { useEffect, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import UserModel from "../models/userModel";
import SpinnersClass from "../components/common/spinner/spinner";
import ErrorRecordModel from "../models/errorRecordModel";
import AppModel from "../models/appModel";
import useHandler from "../hooks/useHandler";
import MigrationConfigModel from "../models/migrationConfigModel";

function PrivateRoute({ component: RouteComponent }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);

  const { getMigrationInfo } = useContext(MigrationConfigModel.Context);
  const userModel = useContext(UserModel.Context);
  const errorRecordModel = useContext(ErrorRecordModel.Context);
  const { changeErrorRecordCount } = useContext(AppModel.Context);
  const { errorHandler } = useHandler();

  async function getErrorRecordCount() {
    try {
      const { data } = await errorRecordModel.getRecordCount();
      const { total } = data || {};
      changeErrorRecordCount(total);
    } catch (e) {
      changeErrorRecordCount(0);
    }
  }

  const getMe = async () => {
    try {
      // setIsLoading(true)
      const data = await userModel.getMe();

      if (data._id) {
        setIsAuthed(true);
        return data;
      }

      return false;
    } catch (err) {
      errorHandler(err);
      setIsAuthed(false);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  async function fetchMigrationInfo(me) {
    try {
      await getMigrationInfo("LSPE", {
        code: "get_one_merchant",
        module: "Merchant",
        merchantId: me?.Merchant?._id,
        purpose: "display",
      });

      return true;
    } catch (error) {
      return true;
    }
  }

  async function checkAuth() {
    const me = await getMe();

    if (me) {
      await fetchMigrationInfo(me);
      await getErrorRecordCount();
    }
  }

  useEffect(() => {
    checkAuth();

    window.addEventListener("focus", getMe);

    return () => {
      window.removeEventListener("focus", getMe);
    };
  }, []);

  if (isLoading === true) return <SpinnersClass />;

  if (!isAuthed && !isLoading)
    return <Navigate to={{ pathname: "/" }} replace />;

  return <RouteComponent />;
}

export default PrivateRoute;
