import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "servicePlan";

const servicePlanTypeOption = [
  { value: "hourly", label: "Hourly" },
  // { value: "monthly", label: "Monthly" },
  { value: "ev", label: "Ev Charge" },
  { value: "flexibleRenewal", label: "Monthly (Renewal)" },
  { value: "recurring", label: "Monthly (Recurring)" },
  { value: "timeshare", label: "Timeshare" },
];

const useServicePlanModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);
  const { getList, getOne, createOne, updateOne, deleteOne, getSchema } =
    BaseActions(module, {
      Merchant,
      isSystem,
      token,
    });

  async function updatePriceList(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/priceList`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getPriceSettingGroup(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/priceSettings`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getServiceAddOns(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/serviceAddOns`,
      isSystem ? params : { Merchant, ...params },
      null
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    deleteOne,
    updatePriceList,
    getSchema,
    servicePlanTypeOption,
    getPriceSettingGroup,
    getServiceAddOns,
  };
};

const ServicePlanModel = BaseModel(useServicePlanModel, "ServicePlanModel");

export { useServicePlanModel };
export default ServicePlanModel;
