import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "parkingTransaction";

const useParkingTransactionModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne } = BaseActions(module, {
    Merchant,
    isSystem,
    token,
  });

  async function exportToCSV(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/action/export/csv`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        false,
        headers,
        true
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateLowConfidenceLPN(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/updateLowConfidenceLpn/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getPaymentInfo(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/paymentInfo/${id}`,
      isSystem ? params : { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updatePaymentInfo(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/paymentInfo/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getLogs(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/logs`,
      isSystem ? params : { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function manualCreateOne(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/manual/create`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { merchantId: Merchant, ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function manualVoidOne(id) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/manual/void/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function manualUpdateLPN(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/manual/updateLpn/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function manualImport(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/manual/batchImport`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    exportToCSV,
    updateLowConfidenceLPN,
    getPaymentInfo,
    updatePaymentInfo,
    getLogs,
    manualCreateOne,
    manualVoidOne,
    manualUpdateLPN,
    manualImport,
  };
};

const ParkingTransactionModel = BaseModel(
  useParkingTransactionModel,
  "ParkingTransactionModel"
);

export { useParkingTransactionModel };
export default ParkingTransactionModel;
