import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";

const TextInput = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: theme.primary,
    transition: "300ms",
  },

  "& label.Mui-error": {
    color: theme.danger,
    transition: "300ms",
  },

  "& label": {
    transition: "300ms",
    color: "#B7B7B7",
  },

  "&:hover label": {
    transition: "300ms",
    color: theme.primary,

    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },

  "&:hover label.Mui-error": {
    transition: "300ms",
    color: theme.danger,
  },

  // "& .MuiInputAdornment-positionStart": {
  //   whiteSpace: "pre-wrap",
  // },

  "& .MuiOutlinedInput-root": {
    "& .MuiInputAdornment-root.MuiInputAdornment-positionStart": {
      "& p": {
        color: "#BABABA",
        maxWidth: 100,
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },

    "&.Mui-disabled": {
      "&:hover fieldset": {
        borderColor: "rgba(0, 0, 0, 0.38)",
        transition: "300ms",
      },
      "& fieldset": {
        opacity: 0.3,
        backgroundColor: theme.disable,
      },
    },
    "& fieldset": {
      borderRadius: "1rem",
      borderColor: "#DBDBDB",
      transition: "300ms",
    },
    "&:hover fieldset": {
      borderColor: theme.primary,
      transition: "300ms",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.primary,
      transition: "300ms",
      borderWidth: "1px",
    },
    "& input": {
      color: theme.text,
      ":-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 1000px white inset",
        backgroundColor: "#fff !important",
      },
    },
    "& input:-webkit-autofill": {
      borderRadius: "1rem",
    },
  },

  "& .MuiOutlinedInput-root.Mui-error": {
    color: "red",
    "& fieldset": {
      borderColor: theme.danger,
      transition: "300ms",
    },
    "&:hover fieldset": {
      borderColor: theme.danger,
      transition: "300ms",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.danger,
      transition: "300ms",
      borderWidth: "1px",
    },
  },

  width: "100%",

  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button ": {
    WebkitAppearance: "none",
  },
}));

const FilterInput = styled(TextInput)`
  margin-right: 20px;
  min-width: 250px;

  .MuiInputBase-root {
    &:before {
      border-bottom: 1px solid #dedede;
    }
    &:hover {
      &:before {
        border-bottom: 1px solid #dedede !important;
      }
    }
  }

  .MuiInput-underline {
    color: #888;
  }

  svg {
    fill: ${({ theme }) => theme.primary};
  }
`;

export default TextInput;
export { TextInput, FilterInput };
