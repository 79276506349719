import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "district";

const region = ["Hong Kong Island", "Kowloon", "New Territories"];

const useDistrictModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, deleteOne } = BaseActions(
    module,
    { Merchant, isSystem, token }
  );

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    region,
    deleteOne,
  };
};

const DistrictModel = BaseModel(useDistrictModel, "DistrictModel");

export { useDistrictModel };
export default DistrictModel;
