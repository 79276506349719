import React from "react";
import Alerts from "./Alert";
import Toasts from "./Toast";

export default function IntegratedPopup() {
  return (
    <>
      <Alerts />
      <Toasts />
    </>
  );
}
