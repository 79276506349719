import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "priceSetting";

const usePriceSetting = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, deleteOne, getSchema } =
    BaseActions(module, {
      Merchant,
      isSystem,
      token,
    });

  async function previewNewHourlyPolicy(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/preview`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    deleteOne,
    getSchema,
    previewNewHourlyPolicy,
  };
};

const PriceSettingModel = BaseModel(usePriceSetting, "PriceSettingModel");

export { usePriceSetting };
export default PriceSettingModel;
