import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "siteConfig";

const useSiteConfigModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);
  const { getList, getOne, createOne, updateOne, getSchema } = BaseActions(
    module,
    {
      Merchant,
      isSystem,
      token,
    }
  );

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getSchema,
  };
};

const SiteConfigModel = BaseModel(useSiteConfigModel, "SiteConfigModel");

export { useSiteConfigModel };
export default SiteConfigModel;
