const DEFAULT_THEME = {
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  primary: "#21BFBC",
  secondary: "#F2FFFF",
  disable: "#efefef",
  danger: "#d32f2f",
  border: "#C3E9E8",
  light: "#BAEAE9",
  text: "#5a5a5a",
  gray: "#DBDBDB",
  darkBlue: "#1A6AAF",
  buttonSecondary: "#BAEAE9",
  black: "#504F4F",
  white: "#fff",
  lightgray: "#F1F0F0",
  darkgray: "#747474",
  disablegray: "#D5D4D4",
  imagegray: "#929191",
  buttongray: "#d4d0d0",
  textgray: "#bababa",
  success: "#2bd95d",
  info: "#3498db",
  warning: "#f1c40f",
  error: "#e74c3c",
  operational: {
    primary: "#FFC107",
    border: "#FFEAD8",
    secondary: "#e6e6e6",
    text: "#5a5a5a",
    gray: "#e9ecef",
    textGray: "#bababa",
    darkBlue: "#0d6efd",
    blue: "#e6f0ff",
    textBlue: "#0d6efd",
    confirm: "#FFEAD8",
    textConfirm: "#FFC107",
    success: "#B4F3E1",
    textSuccess: "#20C997",
    danger: "#d32f2f",
    bgDanger: "#FFE0E3",
    textDanger: "#E25563",
    textPrimary: "#000000",
    black: "#000000",
    white: "#fff",
    light: "#fad977",
    disable: "#efefef",
  },
};

export default DEFAULT_THEME;
