import styled from "styled-components";
import Badge from "@mui/material/Badge";

export const SubTreeButton = styled.button`
  color: ${({ theme, $currentPath, path }) =>
    $currentPath === path ? theme.primary : theme.gray};
  cursor: pointer;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  position: relative;
  width: 100%;
  background-color: ${({ $currentPath, path, theme }) =>
    $currentPath === path ? theme.secondary : "transparent"};
  transition: 300ms all ease;
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.primary};
  }
  white-space: nowrap;
  padding-left: 50px;
`;

// &:hover {
//   &:before {
//     visibility: visible;
//     transform: scaleX(1);
//   }
// }
//
// &:before {
//   content: "";
//   position: absolute;
//   width: 80%;
//   height: 2px;
//   bottom: 0;
//   margin: -5px 0;
//   background-color: ${({ theme }) => theme.primary};
//   visibility: ${({ currentPath, path }) =>
//     currentPath === path ? "visible" : "hidden"};
//   transform: ${({ currentPath, path }) =>
//     currentPath === path ? "scaleX(1)" : "scaleX(0)"};
//   transition: all 0.4s ease-in-out 0s;
// }

export const AAA = styled.div`
  color: red;
`;

export const ErrorRecordCount = styled(Badge)`
  margin-left: 20px;
  .MuiBadge-badge {
    color: white;
    background-color: red;
  }
`;
