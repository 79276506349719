function APIHelper(
  method,
  url,
  data,
  isJSON = true,
  headers = null,
  resultToText = false,
  resultToBlob = false
) {
  if (!headers) {
    headers = {
      Accept: "application/json, text/plain, /",
      "Content-Type": "application/json",
    };
  }
  let isFailed = false;
  let responseStatus = "";

  // const urlWithToken = new URL(url);
  // if (!urlWithToken.searchParams.has('access_token')) {
  //   urlWithToken.searchParams.set(
  //     'access_token',
  //     process.env.REACT_APP_API_TOKEN,
  //   );
  //   url = urlWithToken.toString();
  // }

  const options = { method, headers };

  if (data && isJSON) options.body = JSON.stringify(data);

  if (!isJSON) options.body = data;

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 202) {
          if (resultToBlob) {
            return res.blob();
          }
          if (resultToText) {
            return res.text();
          }
          return res.json();
        }
        responseStatus = res.status;
        isFailed = true;

        return res.json();
      })
      .then((response) => {
        const err = {
          ...response,
          responseStatus,
        };
        if (isFailed) throw err;

        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default APIHelper;
