import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "promotionGroup";

const usePromotionGroupModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);
  const { getList, getOne, createOne, updateOne } = BaseActions(module, {
    Merchant,
    isSystem,
    token,
  });
  return {
    getList,
    getOne,
    createOne,
    updateOne,
  };
};

const PromotionGroupModel = BaseModel(
  usePromotionGroupModel,
  "PromotionGroupModel"
);

export { usePromotionGroupModel };

export default PromotionGroupModel;
