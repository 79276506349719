/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from "react";
import { isEmpty } from "base/BaseHelper";
import { StyledSpan } from "base/BaseStyle";
import { FilterInput, TextInput } from "./CustomInput_styles";
import PasswordAdornment from "./PasswordAdornment";

function CustomInput({
  label,
  styles,
  type,
  error,
  value,
  onChange,
  variant,
  defaultValue,
  disabled,
  inputProps,
  components,
  helperText,
  placeholder,
  multiline,
  rows,
  Ref,
  step,
  maxLength,
  readOnly = false,
  ignoreSpaces,
  target = "normal",
  name,
  autoFocus,
}) {
  const [showPassword, setShowPassword] = useState(false);

  function getType() {
    if (type === "password" && showPassword) {
      return "text";
    }
    if (type === "password" && !showPassword) {
      return "password";
    }

    return type;
  }

  if (readOnly) {
    return <StyledSpan>{value}</StyledSpan>;
  }

  if (target === "filter") {
    return (
      <FilterInput
        id={name}
        helperText={helperText}
        defaultValue={defaultValue}
        variant={variant}
        error={error}
        label={label}
        style={{
          ...styles,
        }}
        value={value}
        onChange={
          type === "number"
            ? (e) => onChange(parseInt(e.target.value, 10))
            : onChange
        }
        placeholder={placeholder}
        disabled={disabled}
        components={components}
        onKeyDown={(e) => {
          if (type === "number" && ["e", "E", "+", "-"].includes(e.key))
            e.preventDefault();
        }}
      />
    );
  }

  return (
    <TextInput
      id={name}
      onWheel={(event) => {
        event.target.blur();
      }}
      onKeyDown={(e) => {
        if (
          (type === "number" && ["e", "E", "+", "-"].includes(e.key)) ||
          (ignoreSpaces && e.keyCode === 32)
        )
          e.preventDefault();
      }}
      autoFocus={autoFocus}
      inputRef={Ref}
      rows={rows}
      multiline={multiline}
      helperText={helperText}
      defaultValue={defaultValue}
      variant={variant}
      error={!isEmpty(error)}
      type={getType()}
      label={label}
      style={{
        ...styles,
      }}
      value={value}
      onChange={
        type === "number"
          ? (e) => {
              if (
                (!e.target.value && e.target.value !== "0") ||
                e.target.value < 0
              ) {
                onChange("");
                return;
              }
              onChange(parseFloat(e.target.value, 10));
            }
          : onChange
      }
      placeholder={placeholder}
      disabled={disabled}
      components={components}
      InputProps={{
        inputProps: {
          step,
          maxLength,
          readOnly,
        },
        endAdornment:
          type === "password" ? (
            <PasswordAdornment
              setShowPassword={setShowPassword}
              showPassword={showPassword}
            />
          ) : null,
        ...inputProps,
      }}
    />
  );
}

export default CustomInput;
