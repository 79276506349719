import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "permission";

const usePermissionHook = (savedModelInstance = {}) => {
  const { isSystem, token } = useContext(UserModel.Context);
  const { getList, getOne, createOne, updateOne } = BaseActions(module, {
    isSystem,
    token,
  });

  return {
    getList,
    updateOne,
    createOne,
    getOne,
  };
};

const PermissionModel = BaseModel(usePermissionHook, "PermissionModel");

export { usePermissionHook };
export default PermissionModel;
