export default function ModuleTable(module) {
  const digitOnly = module.replace(/[a-zA-Z]/g, "");
  switch (digitOnly) {
    case "001":
      return "Activation Record";
    case "002":
      return "Auth";
    case "003":
      return "Awards";
    case "004":
      return "Booking";
    case "005":
      return "Booking Request";
    case "006":
      return "Camera";
    case "007":
      return "Camera Connection Log";
    case "008":
      return "Car Park";
    case "009":
      return "Contractor";
    case "010":
      return "Car Park Receipt";
    case "011":
      return "Changing Transaction";
    case "012":
      return "Cheque Log";
    case "013":
      return "Collection Method";
    case "014":
      return "Credential";
    case "015":
      return "Credit Card";
    case "016":
      return "Date Price";
    case "017":
      return "District";
    case "018":
      return "Enquiry";
    case "019":
      return "Error Record";
    case "020":
      return "EV Lock";
    case "021":
      return "External App";
    case "022":
      return "External App Auth";
    case "023":
      return "FAQ";
    case "024":
      return "Fee Table";
    case "025":
      return "FPS Log";
    case "026":
      return "Gate Action Log";
    case "027":
      return "Gate Action Log Remark";
    case "028":
      return "Holiday";
    case "029":
      return "Hourly Policy";
    case "030":
      return "Import Batch";
    case "031":
      return "Import Entry";
    case "032":
      return "Invoice";
    case "033":
      return "Lease";
    case "034":
      return "Media";
    case "035":
      return "Merchant";
    case "036":
      return "Migration Config";
    case "037":
      return "Migration Connection";
    case "038":
      return "News";
    case "039":
      return "Notification";
    case "040":
      return "Notification Provider";
    case "041":
      return "Notification Template";
    case "042":
      return "Operational Log";
    case "043":
      return "OTP";
    case "044":
      return "Parking Area";
    case "045":
      return "Parking Rate";
    case "046":
      return "Parking Slot";
    case "047":
      return "Parking Transaction";
    case "048":
      return "Payment";
    case "049":
      return "Payment Method";
    case "050":
      return "Payment Transaction";
    case "051":
      return "Permission";
    case "052":
      return "Policy Plan";
    case "053":
      return "Price Setting";
    case "054":
      return "Price Setting Group";
    case "055":
      return "Price Table";
    case "056":
      return "Pricing Scheme";
    case "057":
      return "Promotion";
    case "058":
      return "Promotion Group";
    case "059":
      return "Promotion History";
    case "060":
      return "Quota";
    case "061":
      return "Receipt";
    case "062":
      return "Resident OCR";
    case "063":
      return "Service";
    case "064":
      return "Service Add On";
    case "065":
      return "Service Plan";
    case "066":
      return "Service Tag";
    case "067":
      return "Service Type";
    case "068":
      return "Seven Eleven Log";
    case "069":
      return "Site Config";
    case "070":
      return "Snap Shot";
    case "071":
      return "Token";
    case "072":
      return "Transaction";
    case "073":
      return "Transmission";
    case "074":
      return "User";
    case "075":
      return "User Group";
    case "076":
      return "Vehicle";
    case "077":
      return "Vehicle Group";
    case "078":
      return "Vehicle Type";
    case "079":
      return "Waiting List Round";
    case "080":
      return "Internal Service";
    case "081":
      return "Membership";
    case "082":
      return "ViolationLog";

    default:
      return "";
  }
}
