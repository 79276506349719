import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "Faq";

const useFAQsModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, deleteOne } = BaseActions(
    module,
    { Merchant, isSystem, token }
  );

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    deleteOne,
  };
};

const FAQsModel = BaseModel(useFAQsModel, "FAQsModel");

export { useFAQsModel };
export default FAQsModel;
