import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "GateActionLogRemark";

const useGateActionLogRemarkModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token, userData } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, getSchema, deleteOne } =
    BaseActions(module, {
      Merchant,
      isSystem: userData?.level === "super" || isSystem,
      token,
    });

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getSchema,
    deleteOne,
  };
};

const GateActionLogRemarkModel = BaseModel(
  useGateActionLogRemarkModel,
  "GateActionLogRemarkModel"
);

export { useGateActionLogRemarkModel };
export default GateActionLogRemarkModel;
