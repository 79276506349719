import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import BaseModel from "base/BaseModel";
import APIHelper from "base/APIHelper";
import UserModel from "./userModel";

const module = "errorRecord";
const useErrorRecordsModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, getSchema, deleteOne } =
    BaseActions(module, { Merchant, isSystem, token });

  async function getRecordCount(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/errorCount`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function exportToCSV(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/export`,
      isSystem ? params : { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateStatus(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/status`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    deleteOne,
    getSchema,
    getRecordCount,
    exportToCSV,
    updateStatus,
  };
};

const ErrorRecordModel = BaseModel(
  useErrorRecordsModel,
  "ErrorNotificationsModel"
);

export { useErrorRecordsModel };
export default ErrorRecordModel;
