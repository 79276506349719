import React from "react";
import styled, { keyframes } from "styled-components";
import { useTheme } from "@mui/system";

const loader = keyframes`
  0%,10%,100% {
		width: 80px;
		height: 80px;
	}
	65% {
		width: 150px;
		height: 150px;
	}
`;

const LoaderBlock = keyframes`
 	0%, 30% { 
		transform: rotate(0);
	}
	55% {
		background-color:#E5F4FF;
	}
	100% {
		transform: rotate(90deg);

	}
 
`;

const LoaderBlockInverse = keyframes`
 	0%, 20% { 
		transform: rotate(0);
	}
	55% {
		background-color:#E5F4FF;
	}
	100% {
		transform: rotate(-90deg);
	}
 
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

const Loader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 24px;
    color: ${({ theme }) => theme.text};
  }

  & > div {
    width: 80px;
    height: 80px;
    transform: rotate(45deg) translate3d(0, 0, 0);
  }

  span {
    animation: ${loader} 1.2s infinite ease-in-out;
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.primary};
    animation: ${LoaderBlock} 1.2s infinite ease-in-out both;

    &:nth-child(1) {
      top: 0;
      left: 0;
    }
    &:nth-child(2) {
      top: 0;
      right: 0;
      animation: ${LoaderBlockInverse} 1.2s infinite ease-in-out both;
    }
    &:nth-child(3) {
      bottom: 0;
      left: 0;
      animation: ${LoaderBlockInverse} 1.2s infinite ease-in-out both;
    }
    &:nth-child(4) {
      bottom: 0;
      right: 0;
    }
  }
`;

function Spinnersclass() {
  const theme = useTheme();

  return (
    <Wrapper id="loading-spinner">
      <Loader theme={theme}>
        <div>
          <span />
          <span />
          <span />
          <span />
        </div>
        <p>Loading ... </p>
      </Loader>
    </Wrapper>
  );
}

export default Spinnersclass;
