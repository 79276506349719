import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { ThemeProvider as StyledComponentThemeProvider } from "styled-components";
import moment from "moment";
import "moment/locale/zh-hk";
import DEFAULT_THEME from "constants/theme";
import timezoneMoment from "moment-timezone";
import App from "./App";
import i18n from "./i18n";

library.add(fas);
moment.locale("en");

moment.updateLocale("zh", {
  relativeTime: {
    future: "%s後",
    past: "%s前",
    s: "幾秒",
    ss: "%d 秒",
    m: "1 分鐘",
    mm: "%d 分鐘",
    h: "1 小時",
    hh: "%d 小時",
    d: "1 日",
    dd: "%d 日",
    w: "1 星期",
    ww: "%d 星期",
    M: "1 月",
    MM: "%d 月",
    y: "1 年",
    yy: "%d 年",
  },
});

const defaultTimeZone = "Asia/Hong_Kong";
timezoneMoment.tz.setDefault(defaultTimeZone);

const theme = createTheme(DEFAULT_THEME);

ReactDOM.render(
  <React.StrictMode>
    <StyledComponentThemeProvider theme={DEFAULT_THEME}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </ThemeProvider>
    </StyledComponentThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
