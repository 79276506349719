import { useContext } from "react";
import BaseActions from "../base/BaseActions";
import BaseModel from "../base/BaseModel";
import UserModel from "./userModel";

const module = "migrationConnection";

const useMigrationConnectionModel = (savedModelInstance = {}) => {
  const { token, isSystem, Merchant } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, getSchema } = BaseActions(
    module,
    { isSystem, token, Merchant }
  );

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getSchema,
  };
};

const MigrationConnectionModel = BaseModel(
  useMigrationConnectionModel,
  "MigrationConnectionModel"
);

export { useMigrationConnectionModel };
export default MigrationConnectionModel;
