import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import AppModel from "models/appModel";
import UserModel from "models/userModel";
import Header from "./Header";
import SlideMenu from "./SlideMenu";
import { LayoutContainer } from "./Header.styles";

export default function Layout() {
  const { headerHeight, slideMenuWidth, showMenu } = useContext(
    AppModel.Context
  );
  const { token } = useContext(UserModel.Context);
  const theme = useTheme();

  return (
    <LayoutContainer
      theme={theme}
      $headerHeight={headerHeight}
      $slideMenuWidth={slideMenuWidth}
      $showMenu={showMenu}
    >
      {token ? (
        <>
          <Header />
          <SlideMenu />
          <Outlet />
        </>
      ) : (
        <Outlet />
      )}

      <div className="copyRight">
        <span>
          {`© ${moment().year()} Let’s Park Company (Hong Kong ) Limited . All rights reserved.`}
        </span>
      </div>
    </LayoutContainer>
  );
}
