import { useContext } from "react";
import { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "externalApp";

const useExternalAppModel = (savedModelInstance = {}) => {
  const { token } = useContext(UserModel.Context);

  async function getExternalAppToken(code) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/token/${code}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getExternalAppToken,
  };
};

const ExternalAppModel = BaseModel(useExternalAppModel, "ExternalAppModel");

export { useExternalAppModel };
export default ExternalAppModel;
