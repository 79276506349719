import React from "react";
import Slide from "@mui/material/Slide";
import {
  Body,
  ButtonRow,
  Divider,
  MessageHeader,
  MessageTitle,
  MessageContainer,
  SubTitle,
  Icon,
  MessageDialog,
} from "./Modal.styles";
import CustomButton from "../../Operational/CustomButton";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function MessageModal({
  isOpen = false,
  handleClose = () => {},
  title,
  subTitle,
  description,
  closeText,
  confirmText = "確定",
  onConfirm = () => {},
  closeModel = () => {},
  showConfirmButton = false,
  showCancelButton = true,
  children,
  style,
  icon,
  confirmButtonStyle,
}) {
  return (
    <MessageDialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        handleClose();
        closeModel();
      }}
      aria-describedby="alert-dialog-slide-description"
      padding="0 2rem"
      keepMounted={false}
    >
      <MessageHeader>
        <MessageTitle>{title}</MessageTitle>
        <ButtonRow>
          {children && showCancelButton && (
            <CustomButton
              variant={closeText ? "primary" : "close"}
              onClick={() => {
                handleClose();
                closeModel();
              }}
              style={{ marginLeft: "0.5rem" }}
            >
              {closeText}
            </CustomButton>
          )}
        </ButtonRow>
      </MessageHeader>
      {children && showCancelButton && <Divider />}
      <Body style={style}>
        {children || (
          <MessageContainer>
            <Icon src={icon} alt="alert" />
            <SubTitle>{subTitle}</SubTitle>
            <SubTitle>{description}</SubTitle>
            <ButtonRow style={{ marginTop: "30px" }}>
              {showCancelButton && (
                <CustomButton
                  onClick={() => {
                    closeModel();
                    handleClose();
                  }}
                >
                  {closeText}
                </CustomButton>
              )}
              {showConfirmButton && (
                <CustomButton
                  style={confirmButtonStyle}
                  onClick={async () => {
                    try {
                      const result = await onConfirm();
                      if (result) {
                        closeModel();
                        handleClose();
                      }
                    } catch (error) {
                      //
                    }
                  }}
                >
                  {confirmText}
                </CustomButton>
              )}
            </ButtonRow>
          </MessageContainer>
        )}
      </Body>
    </MessageDialog>
  );
}

export default MessageModal;
