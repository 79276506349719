import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "transmission";

const useTransmissionModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne } = BaseActions(module, {
    Merchant,
    isSystem,
    token,
  });

  async function retryTransmission(id) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/retry`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers,
        true
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    retryTransmission,
  };
};

const TransmissionModel = BaseModel(useTransmissionModel, "TransactionModel");

export { useTransmissionModel };
export default TransmissionModel;
