import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import MainRoute from "constants/routes";
import PrivateRoute from "./base/PrivateRoute";
import { BindModels } from "./models";
import SpinnersClass from "./components/common/spinner/spinner";
import "./App.css";
import NotFound from "./views/NotFound";
import Layout from "./components/common/Layout";
import Popup from "./components/common/Popup";

const Auth = React.lazy(() => import("./views/Auth"));

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_JIRA === "true") {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://abtitu.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-ia3qmx/b/9/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=af4f7dc9";

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }

    return null;
  }, []);

  return (
    <Suspense fallback={<SpinnersClass />}>
      <Routes>
        <Route path="/auth/*" element={<Auth />} />

        <Route path="/" element={<Auth />} />

        <Route element={<Layout />}>
          {MainRoute.map(({ path, component }) => (
            <Route
              key={path}
              path={path}
              element={<PrivateRoute component={component} />}
            />
          ))}

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <Popup />
    </Suspense>
  );
}

export default BindModels(App);
