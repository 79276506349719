import { useContext } from "react";
import { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "userGroup";

const useUserGroupModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  async function getList(params, type = "customer") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}`,
      isSystem ? params : { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getOne(id, params, type = "customer") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}/${id}`,
      isSystem ? params : { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      const { records } = response || {};
      const [record = {}] = records || [];

      return Promise.resolve(record);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createOne(data, type = "customer") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Merchant, ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateOne(id, data, type = "customer") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function deleteOne(id, type = "customer") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "DELETE",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function exportToCSV(data, type = "customer") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}/export`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Merchant, ...data },
        true,
        headers,
        false,
        true
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    deleteOne,
    exportToCSV,
  };
};

const UserGroupModel = BaseModel(useUserGroupModel, "UserGroupModel");

export { useUserGroupModel };
export default UserGroupModel;
