import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "invoice";

const useInvoiceModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, getSchema } = BaseActions(
    module,
    {
      Merchant,
      isSystem,
      token,
    }
  );

  async function syncPaymentForOne(id) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/syncPayment/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getLogs(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/logs`,
      isSystem ? params : { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function resendPaymentLink(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/resendPaymentLink`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Merchant, ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function changePaymentMethod(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/pay/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getQRCode(id) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/qrCode`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function cancelOne(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/cancel/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Merchant, ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function regeneratePdf(id, data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${id}/regeneratePdf`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Merchant, ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getSchema,
    syncPaymentForOne,
    getLogs,
    resendPaymentLink,
    changePaymentMethod,
    getQRCode,
    cancelOne,
    regeneratePdf,
  };
};

const InvoiceModel = BaseModel(useInvoiceModel, "InvoiceModel");

export { useInvoiceModel };
export default InvoiceModel;
