import styled from "styled-components";

const BasicButton = styled.button`
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: bold;
  transition: 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    transition: 300ms;
  }

  &:disabled {
    background-color: #dedede;
    color: #fff;
    &:hover {
      box-shadow: none;
    }
  }
`;

const PrimaryButton = styled(BasicButton)`
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ theme }) => theme.textPrimary};
`;

const DangerButton = styled(BasicButton)`
  background-color: ${({ theme }) => theme.danger};
  color: ${({ theme }) => theme.white};
  font-weight: normal;
`;

const CloseButton = styled(BasicButton)`
  background: ${({ theme }) => theme.primary};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

  svg {
    width: 20px;
    height: 20px;
    color: #000000;
  }
`;

const EditButton = styled(BasicButton)`
  background-color: transparent;
  padding: 5px;
  margin: 0;
  svg,
  img {
    color: ${({ color }) => color};
    height: ${({ $sm }) => ($sm ? "16px" : "20px")};
    width: ${({ $sm }) => ($sm ? "16px" : "20px")};
    &:disabled {
    }
  }
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
  &:disabled {
    background-color: unset;
    svg,
    img {
      color: #dedede;
    }
  }
`;

const AddNewButton = styled(BasicButton)`
  padding: 0.5rem;
  border-radius: 4px;
  height: fit-content;
  display: flex;
  background-color: ${({ theme, $bgColor }) => $bgColor || theme.primary};
  color: #000000;

  &:disabled {
    background-color: #dedede;
    &:hover {
      box-shadow: none;
    }
  }

  svg {
    height: 22px;
    width: 22px;
  }
`;

const LinkButton = styled.button`
  color: ${({ theme }) => theme.primary};
  font-size: 20px;
  border-bottom: 1px solid transparent;
  transition: all 300ms ease-in-out;

  &:hover {
    border-bottom: ${({ $hovercolor }) => `1px solid ${$hovercolor}`};
  }
`;

const IconTextButton = styled(BasicButton)`
  padding: 0.5rem 15px;
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ color }) => color};
  svg,
  img {
    margin-right: 1rem;
    height: ${({ $lg }) => ($lg ? "26px" : "20px")};
    width: ${({ $lg }) => ($lg ? "26px" : "20px")};
  }
`;

const IconText = styled.div`
  min-width: 40px;
  text-align: start;
  white-space: nowrap;
  font-size: ${({ $lg }) => ($lg ? "20px" : "18px")};
  height: ${({ $lg }) => ($lg ? "26px" : "20px")};
  line-height: ${({ $lg }) => ($lg ? "26px" : "20px")};
`;

const TextIconButton = styled(BasicButton)`
  padding: 0.5rem 15px;
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ color }) => color};
  img {
    margin-left: 1rem;
    height: 16px;
    width: 16px;
  }
`;

const MoreButton = styled(BasicButton)`
  background: transparent;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: none;

  svg {
    width: 20px;
    height: 20px;
    color: #000000;
  }
`;

const OutlineButton = styled(BasicButton)`
  background-color: ${({ $isActive, $bgColor }) =>
    $isActive ? $bgColor : "transparent"};
  margin: 0;
  color: ${({ color }) => color};
  &:hover {
    background-color: ${({ $bgColor }) => $bgColor};
  }
`;

const TextButton = styled(BasicButton)`
  background-color: transparent;
  padding: 5px;
  margin: 0;
  box-shadow: none;
  width: 100%;
  &:hover {
    box-shadow: none;
  }
`;

const UnderlineButton = styled(BasicButton)`
  color: ${({ color }) => color};
  font-size: 22px;
  border-bottom: 1px solid ${({ color }) => color};
  transition: all 300ms ease-in-out;
  padding: 0 2px 5px 2px;
  box-shadow: none;
  border-radius: 0;
  &:hover {
    box-shadow: none;
  }
`;

export {
  PrimaryButton,
  DangerButton,
  AddNewButton,
  LinkButton,
  EditButton,
  CloseButton,
  IconTextButton,
  IconText,
  TextIconButton,
  MoreButton,
  OutlineButton,
  TextButton,
  UnderlineButton,
};
