import { useContext } from "react";
import BaseActions, { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "notification";

const useNotificationModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, getSchema } = BaseActions(
    module,
    {
      Merchant,
      isSystem,
      token,
    }
  );

  async function getTemplateList(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/notificationTemplate`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getCustomizedMessageList(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/customized`,
      isSystem ? params : { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getOneCustomizedMessage(id, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/customized/${id}`,
      isSystem ? params : { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getSchema,
    getTemplateList,
    getCustomizedMessageList,
    getOneCustomizedMessage,
  };
};

const NotificationModel = BaseModel(useNotificationModel, "NotificationModel");

export { useNotificationModel };
export default NotificationModel;
