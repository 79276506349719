import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";

export default function PasswordAdornment({ setShowPassword, showPassword }) {
  const theme = useTheme();
  return (
    <InputAdornment position="end">
      <IconButton
        onClick={() => setShowPassword(!showPassword)}
        // onMouseDown={handleMouseDownPassword}
        edge="end"
        style={{ marginRight: 10 }}
      >
        {showPassword ? (
          <FontAwesomeIcon
            style={{ color: theme.primary }}
            icon="fa-solid fa-eye-slash"
          />
        ) : (
          <FontAwesomeIcon
            icon="fa-solid fa-eye"
            style={{ color: theme.primary }}
          />
        )}
      </IconButton>
    </InputAdornment>
  );
}
