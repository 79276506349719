import styled, { keyframes } from "styled-components";
import { useTheme } from "@mui/system";
import React from "react";

const load = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  display: flex;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};

  &:after {
    content: " ";
    display: block;
    width: ${({ width }) => `${width - 10}px`};
    height: ${({ height }) => `${height - 10}px`};
    border-radius: 50%;
    border: ${({ color }) => `2px solid ${color}`};
    border-color: ${({ color }) => `${color} transparent ${color} transparent`};
    animation: ${load} 1.2s linear infinite;
  }
`;

export default function LoadingSpinner({
  width = 20,
  height = 20,
  style,
  color = "#fff",
}) {
  const theme = useTheme();
  return (
    <Loader
      id="loading-spinner"
      theme={theme}
      width={width}
      height={height}
      style={style}
      color={color}
    />
  );
}
