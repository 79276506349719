import React, { useContext, useMemo } from "react";
import { useTransition } from "@react-spring/web";
import PopUpModel from "models/popUpModel";
import { StyledP } from "base/BaseStyle";
import CustomButton from "../../CustomButton";
import { typeAttribute, TOAST_TIMEOUT, TRANSACTION_CONFIG } from "./constants";
import { BasicContainer, StyledToast, Icon, Content } from "./styles";

export default function Toasts() {
  const { toastList, toggleToast, theme } = useContext(PopUpModel.Context);

  const refMap = useMemo(() => new WeakMap(), []);
  const cancelMap = useMemo(() => new WeakMap(), []);

  const transitions = useTransition(toastList, {
    from: { opacity: 0, height: 0, life: "100%", x: 20 },
    keys: (item) => item.id,
    enter: (item) => async (next, cancel) => {
      cancelMap.set(item, cancel);
      await next({
        opacity: 1,
        height: refMap.get(item).offsetHeight + 20,
        x: 0,
      });
      await next({ life: "0%" });
    },
    leave: { opacity: 0, height: 0, x: 20 },
    onRest: (result, ctrl, item) => {
      toggleToast(item.id);
    },
    config: (item, index, phase) => (key) =>
      phase === "enter" && key === "life"
        ? { duration: item?.timeout || TOAST_TIMEOUT }
        : TRANSACTION_CONFIG,
  });

  return (
    <BasicContainer className="ToastMessageContainer">
      {transitions(({ life, ...style }, item) => {
        const { text, type } = item || {};
        const { icon, color } = typeAttribute(type, theme);

        return (
          <StyledToast color={color} style={style}>
            <Content color={color} ref={(ref) => ref && refMap.set(item, ref)}>
              <Icon src={icon} />
              <StyledP color="toastText" fontSize="18" $noMargin>
                {text}
              </StyledP>

              <CustomButton
                variant="close"
                style={{
                  boxShadow: "none",
                  top: 5,
                  right: 5,
                  background: "transparent",
                }}
                textcolor="imagegray"
                onClick={(e) => {
                  e.stopPropagation();
                  if (cancelMap.has(item) && life.get() !== "0%")
                    cancelMap.get(item)();
                }}
              />
            </Content>
          </StyledToast>
        );
      })}
    </BasicContainer>
  );
}
