import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "vehicle";

const useVehicleModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);
  const { getList, getOne, createOne, updateOne, deleteOne } = BaseActions(
    module,
    {
      Merchant,
      isSystem,
      token,
    }
  );

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    deleteOne,
  };
};

const VehicleModel = BaseModel(useVehicleModel, "VehicleModel");

export { useVehicleModel };
export default VehicleModel;
