import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "datePrice";

const useDatePriceModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, deleteOne, getSchema } =
    BaseActions(module, { Merchant, isSystem, token });

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    getSchema,
    deleteOne,
  };
};

const DatePriceModel = BaseModel(useDatePriceModel, "DatePriceModel");

export { useDatePriceModel };
export default DatePriceModel;
