import { useContext } from "react";
import { useTranslation } from "react-i18next";
import UserModel from "models/userModel";
import PopUpModel from "models/popUpModel";
import { errorTextHelper, remarksHelper } from "base/ErrorHelper";

export default function useHandler() {
  const userModel = useContext(UserModel.Context);
  const { ALERT, TOAST } = useContext(PopUpModel.Context);
  const {
    i18n: { language },
  } = useTranslation();

  function toastHandler(errorMsg, type = "error") {
    if (typeof errorMsg !== "string") {
      console.error("Type error: toastHandler message should be string.");
      return;
    }
    TOAST[type](errorMsg);
  }

  function errorHandler(responseBody, inputProps = {}) {
    const {
      errorCode = "",
      title,
      description,
      remarks,
      message,
    } = responseBody || {};

    const alertProps = {
      title: "Error",
      text: "",
      showCancelButton: false,
      allowOutsideClick: false,
      remarks: "",
      ...inputProps,
    };

    // Front End Error
    if (errorCode === "frontEndError") {
      alertProps.title = title;
      alertProps.text = description;
      alertProps.remarks = remarks;
      ALERT.error(alertProps);
      return;
    }

    // v1 format
    if (!errorCode) {
      alertProps.text =
        message?.[language] ||
        message?.en ||
        message ||
        "Unknown Error. Please try again.";
      ALERT.error(alertProps);
      return;
    }

    // v2 format
    const errorText = errorTextHelper(responseBody);
    const multiLangText = errorText?.[language] || errorText;
    const [module, errorType, customCase] = errorCode.split("-") || [];

    if (errorType === "F") {
      toastHandler(multiLangText, "error");
      return;
    }

    alertProps.title = `Error (${errorCode})`;
    alertProps.text = multiLangText;
    alertProps.remarks = remarksHelper(responseBody);

    if (customCase === "PBP006") {
      ALERT.error({
        ...alertProps,
        onConfirm: () => {
          userModel.logout();
        },
      });
      return;
    }

    ALERT.error(alertProps);
  }

  return {
    toastHandler,
    errorHandler,
  };
}
