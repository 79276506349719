import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: ${({ size }) => (size === "middle" ? "1000px" : "800px")};
    border-radius: 1rem;
    overflow: visible;
    margin: ${({ containermargin }) => containermargin};

    @media (max-width: ${({ size }) =>
        size === "middle" ? "1050px" : "850px"}) {
      min-width: 100%;
    }
  }

  .MuiDialogContent-root {
    padding: 1rem 3rem;
  }
`;

export const Header = styled(DialogActions)`
  justify-content: space-between !important;
  padding: 1.5rem 2rem 1rem 2rem !important;
  flex-wrap: wrap !important;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;

  @media (max-width: 650px) {
    width: 100%;
    justify-content: space-around;
    margin-top: 1.5rem;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.primary};
  font-size: 1.5rem;
  font-weight: 500;

  @media (max-width: 650px) {
    width: 100%;
    text-align: center;
  }
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.text};
  margin-top: 1rem;
  text-align: center;
  white-space: pre-wrap;
  line-height: 1.5;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #e0e0e0;
  width: 97.5%;
  align-self: center;
`;

export const MessageDialog = styled(Dialog)`
  .MuiPaper-root {
    justify-content: center;
    align-items: center;
    padding: ${({ padding }) => padding || "4rem 6rem"};
    border-radius: 1.5rem;
    max-width: unset;
    overflow: hidden;
  }

  .MuiDialogContent-root {
    padding: 2rem 0;
  }

  .MuiDialogActions-root {
    margin-top: 20px;
    width: 100%;

    > button {
      width: 50%;
    }
  }
`;

export const MessageHeader = styled(DialogActions)`
  justify-content: space-between !important;
  align-items: center;
  padding: 0 2rem 1rem 2rem !important;
  flex-wrap: wrap !important;
`;

export const MessageTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
`;

export const Body = styled(DialogContent)``;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 400px;
`;

export const Icon = styled.img`
  width: 15rem;
`;

export const SubTitle = styled.span`
  color: ${({ theme }) => theme.text};
  font-size: 20px;
  margin-top: 1rem;
  font-weight: 600;
  text-align: center;
`;
