import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "holiday";

const useHolidayModel = (savedModelInstance = {}) => {
  const { isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne, deleteOne } = BaseActions(
    module,
    { isSystem, token }
  );

  return {
    getList,
    getOne,
    createOne,
    updateOne,
    deleteOne,
  };
};

const HolidayModel = BaseModel(useHolidayModel, "HolidayModel");

export { useHolidayModel };
export default HolidayModel;
