import { useContext } from "react";
import BaseActions from "base/BaseActions";
import BaseModel from "base/BaseModel";
import UserModel from "./userModel";

const module = "importBatch";

const useImportBatchModel = (savedModelInstance = {}) => {
  const { Merchant, isSystem, token } = useContext(UserModel.Context);

  const { getList, getOne, createOne, updateOne } = BaseActions(module, {
    Merchant,
    isSystem,
    token,
  });

  return {
    getList,
    getOne,
    createOne,
    updateOne,
  };
};

const ImportBatchModel = BaseModel(useImportBatchModel, "ImportBatchModel");

export { useImportBatchModel };
export default ImportBatchModel;
