import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DashBoardIcon from "assets/images/dashboard.png";
import ParkingIcon from "assets/images/parking-area.png";
import BookingIcon from "assets/images/checkbox.png";
import PaymentIcon from "assets/images/dollar-symbol.png";
import MessageIcon from "assets/images/email.png";
import SettingIcon from "assets/images/setting.png";
import AccountIcon from "assets/images/user.png";
import OperationIcon from "assets/images/star.png";
import PromotionIcon from "assets/images/promotions.png";
import ReportIcon from "assets/images/report.png";
import {
  DASHBOARD,
  ADMINS,
  SYSTEM_OPTIONS,
  ADMIN_ROLES,
  MERCHANTS,
  CUSTOMERS,
  CAR_PARKS,
  QUOTA_STATUS,
  LEASES,
  BOOKINGS,
  INVOICES,
  TRANSACTION,
  MY_ACCOUNT,
  NOTIFICATIONS,
  CONTENT_UPDATE,
  OCR,
  OPERATIONAL_PAGE,
  CUSTOMIZED_MESSAGE,
  CUSTOMER_GROUP,
  VEHICLE_GROUP,
  ENQUIRY,
  ERROR_NOTIFICATIONS,
  PROMOTION,
  ACCOUNTING_AUDIT,
  EMAIL_TEMPLATE,
  AUDIT_REPORT,
} from "../../../constants/paths";
import { SlideMenuIcon } from "./SlideMenu.styles";

const SLIDE_MENU_ITEMS = [
  {
    name: "Dashboard",
    path: DASHBOARD,
    icon: <SlideMenuIcon src={DashBoardIcon} />,
    level: ["system", "enterprise", "carpark"],
    resource: ["Dashboard"],
  },
  {
    name: "Merchants",
    path: MERCHANTS,
    icon: (
      <FontAwesomeIcon style={{ marginRight: 8 }} icon="fa-solid fa-wrench" />
    ),
    level: ["system", "super"],
    resource: ["Merchants", "MigrationConfig", "MigrationConnection"],
  },
  {
    name: "Parking",
    path: "",
    icon: <SlideMenuIcon src={ParkingIcon} />,
    children: [
      {
        name: "Car Parks",
        path: CAR_PARKS,
        level: ["system", "enterprise", "carpark"],
        resource: ["Carpark"],
      },
      {
        name: "Quota Status",
        path: QUOTA_STATUS,
        level: ["system", "enterprise", "carpark"],
        resource: ["Lease", "Carpark", "ServicePlan"],
      },
      {
        name: "Error Notifications",
        path: ERROR_NOTIFICATIONS,
        level: ["system", "super", "enterprise", "carpark"],
        resource: ["ErrorRecord"],
      },
    ],
  },
  {
    name: "Operational",
    icon: <SlideMenuIcon src={OperationIcon} />,
    path: OPERATIONAL_PAGE,
    level: ["system", "carpark", "enterprise"],
    resource: [
      "Carpark",
      "GateActionLog",
      "GateActionLogRemark",
      "OperationalLog",
      "CameraConnectionLog",
    ],
  },
  {
    name: "Booking",
    path: "",
    icon: <SlideMenuIcon src={BookingIcon} />,
    childrenPath: [LEASES, BOOKINGS, CUSTOMERS],
    children: [
      {
        name: "Leases",
        path: LEASES,
        level: ["system", "enterprise", "carpark"],
        resource: ["Lease"],
      },
      {
        name: "Bookings",
        path: BOOKINGS,
        level: ["system", "enterprise", "carpark"],
        resource: ["Booking"],
      },
      {
        name: "Customers",
        path: CUSTOMERS,
        level: ["system", "enterprise", "carpark"],
        resource: ["CustomerUser"],
      },
      {
        name: "OCR Records",
        path: OCR,
        level: ["system", "enterprise", "carpark"],
        resource: ["ResidentOCR"],
      },
      {
        name: "Customer Group",
        path: CUSTOMER_GROUP,
        level: ["system", "enterprise", "carpark"],
        resource: ["CustomerUserGroup"],
      },
      {
        name: "Vehicle Group",
        path: VEHICLE_GROUP,
        level: ["system", "enterprise", "carpark"],
        resource: ["VehicleGroup"],
      },
    ],
  },
  {
    id: "paymentManagement",
    name: "Payment",
    path: "",
    icon: <SlideMenuIcon src={PaymentIcon} />,
    children: [
      {
        name: "Invoice",
        path: INVOICES,
        level: ["system", "enterprise", "carpark"],
        resource: ["Invoice"],
      },
      {
        name: "Transaction",
        path: TRANSACTION,
        level: ["system", "enterprise", "carpark"],
        resource: ["Transaction"],
      },
    ],
  },
  {
    id: "promotion",
    name: "Promotion",
    path: "",
    icon: <SlideMenuIcon src={PromotionIcon} />,
    children: [
      {
        name: "Single Promotion Code",
        path: PROMOTION,
        level: ["system", "enterprise", "carpark"],
        resource: ["Promotion"],
      },
      {
        name: "Multiple Coupon Generator",
        path: `${PROMOTION}/group`,
        level: ["system", "enterprise", "carpark"],
        resource: ["PromotionGroup"],
      },
    ],
  },
  {
    id: "messages",
    name: "Messages",
    path: "",
    icon: <SlideMenuIcon src={MessageIcon} />,
    children: [
      {
        name: "Notifications",
        path: NOTIFICATIONS,
        level: ["system", "enterprise", "carpark"],
        resource: ["Notification"],
      },
      {
        name: "Customized Message",
        path: CUSTOMIZED_MESSAGE,
        level: ["system", "enterprise", "carpark"],
        resource: ["NotificationTemplate"],
      },
      {
        name: "Enquiry",
        path: ENQUIRY,
        level: ["system", "enterprise", "carpark"],
        resource: ["Enquiry"],
      },
      {
        name: "Email Template",
        path: EMAIL_TEMPLATE,
        level: ["system", "enterprise", "carpark"],
        resource: ["NotificationTemplate"],
      },
    ],
  },
  {
    path: "",
    name: "Setting",
    icon: <SlideMenuIcon src={SettingIcon} />,
    children: [
      {
        id: "systemOptions",
        name: "System Options",
        path: SYSTEM_OPTIONS,
        level: ["system", "super", "enterprise"],
        resource: [
          "DatePrice",
          "CollectionMethod",
          "District",
          "ServiceTag",
          "VehicleType",
          "PaymentMethod",
          "ServiceType",
        ],
      },
      {
        id: "SiteConfig",
        name: "Content Update",
        path: CONTENT_UPDATE,
        level: ["system", "enterprise"],
        resource: ["SiteConfig"],
      },
      {
        id: "AdminAccount",
        name: "Admins",
        path: ADMINS,
        level: ["system", "super", "enterprise"],
        resource: ["AdminUser"],
      },
      {
        id: "Permissions",
        name: "Admin Roles",
        path: ADMIN_ROLES,
        level: ["system", "super", "enterprise"],
        resource: ["Permissions", "AdminUserGroup"],
      },
      // {
      //   id: "companyInformation",
      //   name: "Company Information",
      //   path: "/companyInformation",
      // },
    ],
  },
  {
    id: "accountingReports",
    name: "Accounting & Reports",
    path: "",
    icon: <SlideMenuIcon src={ReportIcon} />,
    children: [
      {
        id: "accountingAudit",
        name: "Accounting & Audit",
        path: ACCOUNTING_AUDIT,
        level: ["system", "super", "enterprise"],
        resource: ["ImportBatch", "ImportEntry"],
      },
      {
        id: "audit",
        name: "Audit Report",
        path: AUDIT_REPORT,
        level: ["system", "super", "enterprise"],
        resource: ["ParkingTransaction", "OperationalLog"],
      },
    ],
  },
  {
    name: "My Account",
    path: MY_ACCOUNT,
    icon: <SlideMenuIcon src={AccountIcon} />,
    level: ["system", "super", "enterprise", "carpark"],
    resource: ["*"],
  },
];

const PERMISSIONS_OPTIONS = [
  {
    value: "Dashboard",
    label: "Dashboard",
    level: ["enterprise", "carpark"],
    module: ["Dashboard"],
  },
  {
    value: "Merchant",
    label: "Merchant",
    level: ["super"],
    module: ["Merchant", "Credential"],
  },
  {
    value: "ErrorRecord",
    label: "ErrorRecord",
    level: ["enterprise", "carpark"],
    module: ["ErrorRecord"],
  },
  {
    value: "Carpark",
    label: "Carpark",
    level: ["enterprise", "carpark"],
    module: [
      "Carpark",
      "HourlyPolicy",
      "ParkingArea",
      "ParkingRate",
      "ParkingSlot",
      "PolicyPlan",
      "PriceSetting",
      "PriceTable",
      "PriceSettingGroup",
      "ServiceAddOn",
      "ServicePlan",
      "BookingRequest",
    ],
  },
  {
    value: "Lease",
    label: "Lease",
    level: ["enterprise", "carpark"],
    module: ["Lease"],
  },
  {
    value: "Booking",
    label: "Booking",
    level: ["enterprise", "carpark"],
    module: ["Booking"],
  },
  {
    value: "CustomerUser",
    label: "Customer",
    level: ["enterprise", "carpark"],
    module: ["CustomerUser"],
  },
  {
    value: "ResidentOCR",
    label: "OCR Records",
    level: ["enterprise", "carpark"],
    module: ["ResidentOCR"],
  },
  {
    value: "CustomerGroup",
    label: "Customer Group",
    level: ["enterprise", "carpark"],
    module: ["CustomerUserGroup"],
  },
  {
    value: "VehicleGroup",
    label: "Vehicle Group",
    level: ["enterprise", "carpark"],
    module: ["VehicleGroup"],
  },
  {
    value: "Invoice",
    label: "Payment",
    level: ["enterprise", "carpark"],
    module: ["Invoice", "Receipt", "Transaction"],
  },
  {
    value: "Notification",
    label: "Messages",
    level: ["enterprise", "carpark"],
    module: ["Notification", "NotificationTemplate", "Enquiry"],
  },
  {
    value: "DatePrice",
    label: "System Options",
    level: ["enterprise"],
    module: [
      "DatePrice",
      "CollectionMethod",
      "District",
      "ServiceTag",
      "VehicleType",
      "PaymentMethod",
      "ServiceType",
    ],
  },

  {
    value: "Promotion",
    label: "Promotion",
    level: ["enterprise"],
    module: ["Promotion", "PromotionGroup"],
  },

  { value: "Holiday", label: "Holiday", level: ["super"], module: ["Holiday"] },
  {
    value: "SiteConfig",
    label: "Content Update",
    level: ["enterprise"],
    module: ["SiteConfig", "News", "FAQ", "Awards", "Service"],
  },
  {
    value: "AdminUserGroup",
    label: "Account Setting",
    level: ["super", "enterprise"],
    module: ["AdminUserGroup", "Permissions"],
  },
  {
    value: "AdminUser",
    label: "Admin User",
    level: ["super", "enterprise"],
    module: ["AdminUser"],
  },
  {
    value: "ImportBatch",
    label: "Reports",
    level: ["super", "enterprise", "carpark"],
    module: ["ImportBatch", "ImportEntry"],
  },
  {
    value: "ParkingTransaction",
    label: "Operational",
    level: ["enterprise", "carpark"],
    module: [
      "ParkingTransaction",
      "OperationalLog",
      "GateActionLogRemark",
      "GateActionLog",
      "CameraConnectionLog",
    ],
  },
  {
    value: "MigrationConfig",
    label: "Migration",
    level: ["enterprise", "super"],
    module: ["MigrationConfig", "MigrationConnection"],
  },
];

const NECESSARY_PERMISSION = [
  {
    resource: "Promotion",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "PromotionGroup",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "DatePrice",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "CollectionMethod",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  { resource: "District", actions: ["read"], level: ["enterprise", "carpark"] },
  {
    resource: "ServiceTag",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "VehicleType",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "PaymentMethod",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "ServiceType",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "Merchant",
    actions: ["read"],
    level: ["super", "enterprise", "carpark"],
  },
  {
    resource: "Media",
    actions: ["read", "create", "update", "delete"],
    level: ["super", "enterprise", "carpark"],
  },
  {
    resource: "Holiday",
    actions: ["read"],
    level: ["super", "enterprise", "carpark"],
  },
  {
    resource: "SiteConfig",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "News",
    actions: ["read"],
    level: ["enterprise"],
  },
  {
    resource: "Awards",
    actions: ["read"],
    level: ["enterprise"],
  },
  {
    resource: "Service",
    actions: ["read"],
    level: ["enterprise"],
  },
  {
    resource: "FAQ",
    actions: ["read"],
    level: ["enterprise"],
  },
  {
    resource: "Permissions",
    actions: ["read"],
    level: ["super", "enterprise", "carpark"],
  },
  {
    resource: "ImportBatch",
    actions: ["read", "create", "update"],
    level: ["super", "enterprise", "carpark"],
  },
  {
    resource: "ImportEntry",
    actions: ["read", "create", "update"],
    level: ["super", "enterprise", "carpark"],
  },
  {
    resource: "GateActionLog",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "GateActionLogRemark",
    actions: ["read", "create"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "OperationalLog",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "ParkingTransaction",
    actions: ["read"],
    level: ["enterprise", "carpark"],
  },
  {
    resource: "MigrationConfig",
    actions: ["read"],
    level: ["super"],
  },
  {
    resource: "MigrationConfig",
    actions: ["read", "create", "update", "delete"],
    level: ["enterprise"],
  },
  {
    resource: "MigrationConnection",
    actions: ["read", "create", "update", "delete"],
    level: ["super", "enterprise"],
  },
];

const EXCEPTION_ACTIONS = [
  // { module: "ActivationRecord", action: "create" },
  // { module: "ActivationRecord", action: "update" },
  // { module: "ActivationRecord", action: "delete" },

  { module: "Booking", action: "delete" },

  { module: "BookingRequest", action: "create" },
  { module: "BookingRequest", action: "delete" },

  // { module: "ChangingTransaction", action: "create" },
  // { module: "ChangingTransaction", action: "update" },
  // { module: "ChangingTransaction", action: "delete" },

  // { module: "ChequeLog", action: "create" },
  // { module: "ChequeLog", action: "update" },
  // { module: "ChequeLog", action: "delete" },

  { module: "Enquiry", action: "delete" },

  { module: "ErrorRecord", action: "create" },
  { module: "ErrorRecord", action: "delete" },

  // { module: "EvLock", action: "create" },
  // { module: "EvLock", action: "update" },
  // { module: "EvLock", action: "delete" },

  // { module: "FPSLog", action: "create" },
  // { module: "FPSLog", action: "update" },
  // { module: "FPSLog", action: "delete" },

  { module: "ImportBatch", action: "create" },
  { module: "ImportBatch", action: "update" },
  { module: "ImportBatch", action: "delete" },

  { module: "ImportEntry", action: "create" },
  { module: "ImportEntry", action: "update" },
  { module: "ImportEntry", action: "delete" },

  { module: "Invoice", action: "create" },
  { module: "Invoice", action: "delete" },

  { module: "Lease", action: "create" },
  { module: "Lease", action: "delete" },

  { module: "Media", action: "update" },
  { module: "Media", action: "delete" },

  { module: "PaymentMethod", action: "create" },
  { module: "PaymentMethod", action: "update" },
  { module: "PaymentMethod", action: "delete" },

  // { module: "PaymentTransaction", action: "create" },
  // { module: "PaymentTransaction", action: "update" },
  // { module: "PaymentTransaction", action: "delete" },

  // { module: "PromotionHistory", action: "create" },
  // { module: "PromotionHistory", action: "update" },
  // { module: "PromotionHistory", action: "delete" },

  { module: "Receipt", action: "create" },
  { module: "Receipt", action: "update" },
  { module: "Receipt", action: "delete" },

  { module: "ResidentOCR", action: "create" },
  { module: "ResidentOCR", action: "delete" },

  // { module: "SevenElevenLog", action: "create" },
  // { module: "SevenElevenLog", action: "update" },
  // { module: "SevenElevenLog", action: "delete" },

  { module: "Transaction", action: "create" },
  { module: "Transaction", action: "delete" },

  // { module: "Transmission", action: "create" },
  // { module: "Transmission", action: "update" },
  // { module: "Transmission", action: "delete" },

  { module: "Quota", action: "create" },
  { module: "Quota", action: "update" },
  { module: "Quota", action: "delete" },

  { module: "Credential", action: "create" },
  { module: "Credential", action: "delete" },
  { module: "Credential", action: "update" },

  { module: "GateActionLog", action: "create" },
  { module: "GateActionLog", action: "update" },
  { module: "GateActionLog", action: "delete" },

  { module: "GateActionLogRemark", action: "update" },
  { module: "GateActionLogRemark", action: "delete" },

  { module: "OperationalLog", action: "create" },
  { module: "OperationalLog", action: "update" },
  { module: "OperationalLog", action: "delete" },

  { module: "MigrationConfig", action: "create" },
  { module: "MigrationConfig", action: "update" },
  { module: "MigrationConfig", action: "delete" },

  { module: "CameraConnectionLog", action: "update" },
  { module: "CameraConnectionLog", action: "delete" },
];

const MONTHLY_DISABLED_LIST = [
  "Enquiry", // slideMenu
  "Notifications", // slideMenu
  "Customized Message", // slideMenu
  "OCR Records", // slideMenu
  "Content Update", // slideMenu
  "Accounting & Reports", // slideMenu
  "Date&Price", // SystemOptions
  "collectionMethod", // SystemOptions
  "serviceTags", // SystemOptions
  "vehicleTypes", // SystemOptions
  "paymentMethod", // SystemOptions
];

export default SLIDE_MENU_ITEMS;
export {
  SLIDE_MENU_ITEMS,
  EXCEPTION_ACTIONS,
  NECESSARY_PERMISSION,
  PERMISSIONS_OPTIONS,
  MONTHLY_DISABLED_LIST,
};
